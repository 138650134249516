import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BASEAPIURL, useAppContext } from '../../Context/AppContext';
import toast from 'react-hot-toast';
import { useDashboardContext } from './Context/DashboardContext';
const banks = [
  {
    "id": 1,
    "name": "Habib Bank Limited (HBL)",
    "image": "https://example.com/images/hbl.png"
  },
  {
    "id": 2,
    "name": "United Bank Limited (UBL)",
    "image": "https://example.com/images/ubl.png"
  },
  {
    "id": 3,
    "name": "National Bank of Pakistan (NBP)",
    "image": "https://example.com/images/nbp.png"
  },
  {
    "id": 4,
    "name": "Meezan Bank",
    "image": "https://example.com/images/meezan.png"
  },
  {
    "id": 5,
    "name": "Allied Bank Limited (ABL)",
    "image": "https://example.com/images/abl.png"
  },
  {
    "id": 6,
    "name": "Bank Alfalah",
    "image": "https://example.com/images/alfalah.png"
  },
  {
    "id": 7,
    "name": "Faysal Bank",
    "image": "https://example.com/images/faysal.png"
  },
  {
    "id": 8,
    "name": "Standard Chartered Pakistan",
    "image": "https://example.com/images/standard_chartered.png"
  },
  {
    "id": 9,
    "name": "Askari Bank",
    "image": "https://example.com/images/askari.png"
  },
  {
    "id": 10,
    "name": "Bank of Punjab (BOP)",
    "image": "https://example.com/images/bop.png"
  },
  {
    "id": 11,
    "name": "Silk Bank",
    "image": "https://example.com/images/silk.png"
  },
  {
    "id": 12,
    "name": "Summit Bank",
    "image": "https://example.com/images/summit.png"
  },
  {
    "id": 13,
    "name": "JS Bank",
    "image": "https://example.com/images/jsbank.png"
  },
  {
    "id": 14,
    "name": "Soneri Bank",
    "image": "https://example.com/images/soneri.png"
  },
  {
    "id": 15,
    "name": "MCB Bank",
    "image": "https://example.com/images/mcb.png"
  }
];

const coins = [
  {
    "name": "Bitcoin",
    "symbol": "BTC",
    "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Bitcoin-icon.png"
  },
  {
    "name": "TRON",
    "symbol": "TRX",
    "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Ethereum-icon.png"
  },
  // {
  //   "name": "Binance Coin",
  //   "symbol": "BNB",
  //   "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Binance-Coin-icon.png"
  // },
  {
    "name": "Tether",
    "symbol": "USDT",
    "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Tether-icon.png"
  },
  // {
  //   "name": "Ripple",
  //   "symbol": "XRP",
  //   "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Ripple-icon.png"
  // }
];
const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState(1);
  const location = useLocation();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const { allPlans } = useAppContext();
  const onChangeCard = (index) => {
    setPaymentMethod(index);
  }

  useEffect(() => {
    getAllPaymentMethod();
  }, [])


  const getAllPaymentMethod = async () => {
    try {
      const response = await axios.get(`${BASEAPIURL}paymentMethod/web`);
      if (response.status === 200) {
        setPaymentMethods(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }




  const easypaisa = paymentMethods?.filter(v => v?.type == "EASYPAISA");
  const jazzCash = paymentMethods?.filter(v => v?.type == "JAZZCASH");
  const bank = paymentMethods?.filter(v => v?.type == "BANK");
  const crypto = paymentMethods?.filter(v => v?.type == "CRYPTO");


  const [accNumber, setAccNumber] = useState('');
  const [accName, setAccName] = useState('');
  const [selectBank, setSelectBank] = useState('');

  const [planId, setPlanId] = useState('');
  const [amount, setAmount] = useState('');
  const [image, setImage] = useState(null);
  const { userInfo } = useAppContext();
  const { getAllUserDeposit, getAllUserWithDraw } = useDashboardContext();




  const addDeposit = async () => {
    setAccName(paymentMethod == 4 && crypto[0]?.bankAndCoinName)
    if (accNumber == "" || accName == "" || amount == "" || image == null) {
      toast.error("Please Fill All Required Fields");
    } else {
      const formData = new FormData();
      if (paymentMethod == 3 || paymentMethod == 4) {
        if (selectBank == "") {
          toast.error("Please Fill All Required Fields");
          return;
        } else {
          formData.append("bankAndCoinName", selectBank);
        }
      }
      try {
        const paymentMethodId = paymentMethod == 1 ? easypaisa[0]?._id : paymentMethod == 3 ? bank[0]?._id : paymentMethod == 4 ? crypto[0]?._id : jazzCash[0]?._id;
        formData.append('userId', userInfo?._id);
        formData.append('paymentMethodId', paymentMethodId);
        formData.append('planId', "6750e7369f33df08f03ee504");
        formData.append('amount', amount);
        formData.append('accountNumber', accNumber);
        formData.append('accountName', accName);
        formData.append("image", image);
        const response = await axios.post(`${BASEAPIURL}userByPlan/create`, formData);
        if (response.status == 200) {
          getAllUserDeposit()
          toast.success("Deposit Request Successfully");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }




  const [wAccNumber, setWAccNumber] = useState("");
  const [wAccName, setWAccName] = useState("");
  const [wAmount, setWAmount] = useState("");
  const [wBank, setWBank] = useState("");




  const onWithDrawRequest = async () => {
    // alert("dddd");
    if (wAccNumber == "" || wAccName == "" || wAmount == "") {
      toast.error("Please Fill All Required Fields");
    } else if (userInfo?.totalAmount == 0) {
      toast.error("You Amount is zero therefor you can not withdraw");
    } else {
      if (paymentMethod == 3 || paymentMethod == 4) {
        // alert("ddd");
        if (wBank == "") {
          toast.error("Please Fill All Required Fields");
          return;
        }
      } 
      // else {
        if (wAmount < 5) {
          toast.error("You cannot make a withdrawal until your balance reaches $5.");
          return;
        } else if (wAmount > userInfo?.totalAmount) {
          toast.error("You cannot make a withdrawal until your balance reaches.");
          return;
        } else {
         
          try {
            const paymentMethodId = paymentMethod == 1 ? easypaisa[0]?._id : paymentMethod == 3 ? bank[0]?._id : paymentMethod == 4 ? crypto[0]?._id : jazzCash[0]?._id;
            const response = await axios.post(`${BASEAPIURL}withdraw/create`, {
              userId: userInfo?._id,
              amount: wAmount,
              paymentMethodId: paymentMethodId,
              accountNumber: wAccNumber,
              accountName: wAccName,
              bankAndCoinName: wBank,
            });
            if (response.status == 200) {
              getAllUserWithDraw()
              toast.success("WidthDraw Request Successfully");
            }
          } catch (error) {
            console.log(error);
          }
        }
      // }
    }
  }



  return (
    <div className='container'>
      <div className="payment-row">
        <div className="col-left">
          <h3 style={{ marginTop: 10 }}>{location.pathname == "/user/payment" ? "Deposit Method" : "Withdraw Method"}</h3>
          <div className='account-row'>
            <div onClick={() => { onChangeCard(1) }} className={`account-card ${paymentMethod == 1 && "active"}`}>
              <img src='images/easypaisa-logo.png' />
              <p>Invest with Easypaisa</p>
            </div>
            <div onClick={() => { onChangeCard(2) }} className={`account-card ${paymentMethod == 2 && "active"}`}>
              <img src='images/jazz-logo.png' />
              <p>Invest with Jazz Cash</p>
            </div>
            <div onClick={() => { onChangeCard(3) }} className={`account-card ${paymentMethod == 3 && "active"}`}>
              <img src='images/bank-icon.png' />
              <p>Invest with local Bank</p>
            </div>
            <div onClick={() => { onChangeCard(4) }} className={`account-card ${paymentMethod == 4 && "active"}`}>
              <img src='images/btcCoinIcon.png' />
              <p>Invest with Cryptocurrency</p>
            </div>
          </div>
        </div>
        {location.pathname != "/user/payment" ?
          <div className='col-right'>
            <h3>Additional Info</h3>

            <div className='payment-form'>
              {
                paymentMethod == 3 && <div class="input-container">
                  <div class="dropdown">
                    <button class="dropdown-btn">{wBank != '' ? wBank : "Select an option"}</button>
                    <div class="dropdown-content"

                    >
                      {banks.map((b, i) => {
                        return (
                          <div
                            onClick={() => {
                              setWBank(b.name)
                            }}
                            key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} ({b.name}) <img src={b.icon} alt="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSxA3xHc3GksU-vnfyN054dxwZXLYr2HcK_A&s" /></div>
                        )
                      })
                      }
                    </div>
                  </div>
                  {/* <label for="account-number">Select Bank</label>
                  <select id="account-number"
                    onChange={(e) => setWBank(e.target.value)}
                  >
                    <option value="">--Select Bank--</option>
                    {
                      banks.map((b, i) => {
                        return (
                          <option key={i} value={b.name}>{b.name}</option>
                        );
                      })
                    }
                  </select> */}
                </div>
              }

              {
                paymentMethod == 4 && <div class="input-container">
                  <div class="dropdown">
                    <button class="dropdown-btn">{wBank != '' ? wBank : "Select an option"}</button>
                    <div class="dropdown-content">
                      {coins.map((b, i) => {
                        return (
                          <div
                            onClick={() => {
                              setWBank(b.name)
                            }}
                            key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} ({b.symbol}) <img src={b.icon} alt="Check" /></div>
                        )
                      })
                      }
                    </div>
                  </div>
                  {/* <label for="account-number">Select Coin</label>
                  <select id="account-number"
                    onChange={(e) => setWBank(e.target.value)}
                  >
                    <option value="">--Select Coin--</option>
                    {
                      coins.map((b, i) => {
                        return (
                          <option key={i} value={b.name}>{b.name} ({b.symbol})</option>
                        );
                      })
                    }
                  </select> */}
                </div>
              }
              <div class="input-container">
                <label for="account-number">{paymentMethod == 4 ? "Coin Address" : "Account Number"}</label>
                <input type="text" onChange={(e) => setWAccNumber(e.target.value)} value={wAccNumber} id="account-number" placeholder={paymentMethod == 4 ? "Enter Coin Address" : "Enter Acc Number"} />
              </div>
              {paymentMethod != 4 && <div class="input-container">
                <label for="account-number">Account Holder Name</label>
                <input type="text" onChange={(e) => setWAccName(e.target.value)} value={wAccName} id="account-number" placeholder="Enter Holder Name" />
              </div>}
              <div class="input-container">
                <label for="account-number">Amount</label>
                <input type="number" onChange={(e) => setWAmount(e.target.value)} value={wAmount} id="account-number" placeholder="Minimum Withdraw 5$ " />
              </div>

              <p style={{ marginBlock: 10 }}>Withdrawal fee 5%</p>
              <button onClick={onWithDrawRequest} type="submit" className="login-button" >
                Withdraw Request
              </button>
            </div>
          </div>
          : <div className='col-right'>
            <h3>Additional Info</h3>

            {
              paymentMethod == 1 || paymentMethod == 2 ? (<div className='payment-form'>
                <div className='input-row'>
                  <div class="input-container">
                    <label for="account-number">Account Number</label>
                    <input type="text" value={accNumber} onChange={(e) => { setAccNumber(e.target.value) }} id="account-number" placeholder="Enter Acc Number" />
                  </div>
                  <div class="input-container">
                    <label for="account-number">Account Name</label>
                    <input type="text" value={accName} onChange={(e) => { setAccName(e.target.value) }} id="account-number" placeholder="Enter Acc Name" />
                  </div>
                </div>
                <div className='input-row'>
                  {/* <div class="input-container">
                    <label for="account-number">Package</label>
                    <select
                      id="account-number"
                      value={planId}
                      onChange={(e) => {
                        const selectedOption = e.target.selectedOptions[0];
                        const amount = selectedOption?.dataset?.amount; // Access the `data-amount` attribute
                        setAmount(amount);
                        setPlanId(e?.target?.value);
                      }}
                    >
                      <option value="">--Select Package--</option>
                      {allPlans &&
                        allPlans?.map((v, k) => (
                          <option
                            value={v?._id}
                            data-amount={v?.invest.from}
                            key={k}
                          >
                            {v?.type} Invest: {v?.invest?.from}$ Profit: {v?.profit?.from}/
                            {v?.profit?.to}%
                          </option>
                        ))}
                    </select>

                  </div> */}
                  <div class="input-container">
                    <label for="account-number">Amount</label>
                    <input type="text" value={amount} onChange={(e) => { setAmount(e.target.value) }} id="account-number" placeholder="Enter Amount" />
                  </div>
                </div>

                <div class="input-container" style={{ marginTop: '-0.0rem' }}>
                  <label for="account-number">Account Holder Number</label>
                  <input type="text"
                    value={
                      paymentMethod === 1
                        ? easypaisa?.[0]?.accountNumber || ''
                        : jazzCash?.[0]?.accountNumber || ''
                    }
                    id="account-number" placeholder="Enter Acc Number" />
                </div>
                <div class="input-container">
                  <label for="account-number">Account Holder Name</label>
                  <input type="text" id="account-number"
                    value={
                      (paymentMethod === 1
                        ? easypaisa?.[0]?.accountName || ''
                        : jazzCash?.[0]?.accountName || '') +
                      (paymentMethod === 1 ? ' (easypaisa)' : ' (jazzcash)')
                    }
                    placeholder="Enter Holder Name" />
                </div>

                <div class="input-container">
                  <label for="account-number">Invoice Image</label>
                  <input type="file" onChange={(e) => {
                    if (e.target.files?.length > 0) {
                      setImage(e.target.files[0]);
                    }
                  }} id="account-number" placeholder="Enter Holder Name" />
                </div>
                <button onClick={addDeposit} type="submit" className="login-button" >
                  Deposit
                </button>
              </div>) : paymentMethod == 3 ? (
                <div className='payment-form'>
                  <div class="input-container">
                    <div class="dropdown">
                      <button class="dropdown-btn">{selectBank != "" ? selectBank : "Select an option"}</button>
                      <div class="dropdown-content">
                        {banks.map((b, i) => {
                          return (
                            <div
                              onClick={() => setSelectBank(b.name)}
                              key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} <img src={"https://cdn.pixabay.com/photo/2021/06/27/14/42/money-6369029_640.png"} alt="Check" /></div>
                          )
                        })
                        }
                      </div>
                    </div>
                    {/* <label for="account-number">Select Bank</label>
                    <select
                      onChange={(e) => setSelectBank(e.target.value)}
                      id="account-number" >
                      <option value="">--Select Bank--</option>
                      {
                        banks.map((b, i) => {
                          return (
                            <option key={i} value={b.name}>{b.name}</option>
                          );
                        })
                      }
                    </select> */}
                  </div>
                  {/* <div class="input-container">
                    <label for="account-number">Account Number</label>
                    <input type="text" id="account-number" placeholder="Enter Acc Number" />
                  </div> */}
                  <div className='input-row'>
                    <div class="input-container">
                      <label for="account-number">Account Number</label>
                      <input type="text" value={accNumber} onChange={(e) => { setAccNumber(e.target.value) }} id="account-number" placeholder="Enter Acc Number" />
                    </div>
                    <div class="input-container">
                      <label for="account-number">Account Name</label>
                      <input type="text" value={accName} onChange={(e) => { setAccName(e.target.value) }} id="account-number" placeholder="Enter Acc Name" />
                    </div>
                  </div>
                  <div className='input-row'>
                    <div class="input-container">
                      <label for="account-number">Amount</label>
                      <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} id="account-number" placeholder="Enter Amount" />
                    </div>
                    {/* <div class="input-container">
                      <label for="account-number">Package</label>
                      <select id="account-number" >
                        <option value="">--Select Package--</option>
                        {
                          allPlans && allPlans?.map((v, k) => {
                            return <option value={v?._id} key={k}>{v?.type} Invest: {v?.invest?.from}$ Profit: {v?.profit?.from}/{v?.profit?.to}%</option>
                          })
                        }
                      </select>
                    </div> */}
                  </div>

                  <div class="input-container" style={{ marginTop: '-0.0rem' }}>
                    <label for="account-number">Account  Number</label>
                    <input type="text" id="account-number" value={bank[0]?.accountNumber} placeholder="Enter Acc Number" />
                  </div>
                  <div class="input-container">
                    <label for="account-number">Account Holder Name</label>
                    <input type="text" id="account-number" value={bank[0]?.accountName + (` (${bank[0]?.bankAndCoinName})`)} placeholder="Enter Holder Name" />
                  </div>

                  <div class="input-container">
                    <label for="account-number">Invoice Image</label>
                    <input onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setImage(e.target.files[0]);
                      }
                    }} type="file" id="account-number" placeholder="Enter Holder Name" />
                  </div>
                  <button onClick={addDeposit} type="submit" className="login-button" >
                    Deposit
                  </button>
                </div>
              ) : <div className='payment-form'>
                <div class="input-container">
                  <div class="dropdown">
                    <button class="dropdown-btn">{selectBank != '' ? selectBank : "Select an option"}</button>
                    <div class="dropdown-content">
                      {coins.map((b, i) => {
                        return (
                          <div

                            onClick={() => setSelectBank(b.name)}
                            key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} ({b.symbol}) <img src={b.icon} alt="Check" /></div>
                        )
                      })
                      }

                    </div>
                  </div>
                  {/* <label for="account-number">Select Coin</label> */}
                  {/* <select
                    onChange={(e) => setSelectBank(e.target.value)}
                    id="account-number" >
                    <option value="">--Select Coin--</option>
                    {
                      coins.map((b, i) => {
                        return (
                          <option key={i} value={b.name}>{b.name} ({b.symbol})</option>
                        );
                      })
                    }
                  </select> */}
                </div>
                <div class="input-container">
                  <label for="account-number">Coin Address</label>
                  <input type="text" value={accNumber} onChange={(e) => setAccNumber(e.target.value)} id="account-number" placeholder="Enter Coin Address" />
                </div>
                <div className='input-row'>
                  <div class="input-container">
                    <label for="account-number">Amount</label>
                    <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} id="account-number" placeholder="Enter Amount" />
                  </div>
                  {/* <div class="input-container">
                    <label for="account-number">Package</label>
                    <select id="account-number" >
                      <option value="">--Select Package--</option>
                      {
                        allPlans && allPlans?.map((v, k) => {
                          return <option value={v?._id} key={k}>{v?.type} Invest: {v?.invest?.from}$ Profit: {v?.profit?.from}/{v?.profit?.to}%</option>
                        })
                      }
                    </select>
                  </div> */}
                </div>

                <div class="input-container" style={{ marginTop: '-0.0rem' }}>
                  <label for="account-number">Holder Coin Address</label>
                  <input type="text" id="account-number" value={crypto[0]?.accountNumber} placeholder="Enter Acc Number" />
                </div>
                <div class="input-container">
                  <label for="account-number">Coin Name</label>
                  <input type="text" id="account-number" value={crypto[0]?.accountName + (` (${crypto[0]?.bankAndCoinName})`)} placeholder="Enter Holder Name" />
                </div>
                <div class="input-container">
                  <label for="account-number">Invoice Image</label>
                  <input
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setImage(e.target.files[0]);
                      }
                    }}
                    type="file" id="account-number" placeholder="Enter Holder Name" />
                </div>
                <button onClick={addDeposit} type="submit" className="login-button" >
                  Deposit
                </button>
              </div>
            }
          </div>}
      </div>
    </div>
  )
}

export default Payment