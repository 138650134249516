

import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const DashboardContext = createContext();

// export const BASEAPIURL = "http://147.93.128.107:3001/api/v1/";
// export const BASEIMAGEURL = "http://147.93.128.107:3001/";

export const BASEAPIURL = "https://93.127.163.198:3001/api/v1/";
export const BASEIMAGEURL = "https://93.127.163.198:3001";


const DashboardContextProvider = ({ children }) => {
    let BaseApi = axios.create({
        baseURL: BASEAPIURL,
    });
    const [allDeposit, setAllDeposit] = useState(null);
    const [allReferrals, setAllReferrals] = useState(null);

    const [allCurrentProfit, setAllCurrentProfit] = useState(null);
    const [allWithDraw, setAllWithDraw] = useState(null);
    const [allCurrentPlan, setAllCurrentPlan] = useState(null);

    const [allDirectReward, setAllDirectReward] = useState(null);




    const getAllUserDeposit = async () => {
        const userId = window.localStorage.getItem("@userTokenId");

        try {
            const response = await BaseApi.get(`/userByPlan/getAllTransactionsByUser/${userId}`);
            if (response.status == 200 && response.data.data?.length > 0) {
                setAllDeposit(response.data.data);
            } else {
                setAllDeposit([]);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getAllUserDirectReward = async () => {
        const userId = window.localStorage.getItem("@userTokenId");

        try {
            const response = await BaseApi.get(`/userByPlan/getDirectReward/${userId}`);
            if (response.status == 200 && response.data.data?.length > 0) {
                setAllDirectReward(response.data.data);
            } else {
                setAllDirectReward([]);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getAllUserDeposit();
        getAllUserWithDraw();
        getAllProfit();
        getAllCurrentPlan();
        getAllUserDirectReward();
    }, [])


    const getAllUserWithDraw = async () => {
        const userId = window.localStorage.getItem("@userTokenId");

        try {
            const response = await BaseApi.get(`/withdraw/getAllWithByUser/${userId}`);
            if (response.status == 200 && response.data.data?.length > 0) {
                setAllWithDraw(response.data.data);
            } else {
                setAllWithDraw([]);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getAllCurrentPlan = async () => {
        const userId = window.localStorage.getItem("@userTokenId");

        try {
            const response = await BaseApi.get(`/userByPlan/getUserCurrentPlan/${userId}`);
            if (response.status == 200 && response.data.data?.length > 0) {
                setAllCurrentPlan(response.data.data);
            } else {
                setAllCurrentPlan([]);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getAllProfit = async () => {
        const userId = window.localStorage.getItem("@userTokenId");
        try {
            const response = await BaseApi.get(`/user/get-all-profit/${userId}`);
            if (response.status == 200 && response.data.data?.length > 0) {
                console.log(response.data.data);
                setAllCurrentProfit(response.data.data);
            } else {
                setAllCurrentProfit([]);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getAllReferrals = async (code) => {

        // http://localhost:3001/api/v1/user/get-referral/A26476B3
        try {
            const response = await BaseApi.get(`/user/get-referral/${code}`);
            if (response.status == 200 && response.data.data?.length > 0) {
                // console.log(response.data.data);
                setAllReferrals(response.data.data);
            } else {
                setAllReferrals([]);
            }
        } catch (error) {
            console.log(error);
        }
    }


    
    return (
        <DashboardContext.Provider
            value={{
                allDeposit,
                getAllUserDeposit,
                getAllUserWithDraw,
                allWithDraw,
                allDirectReward,
                allCurrentPlan,
                getAllCurrentPlan,
                allCurrentProfit,
                getAllReferrals,
                allReferrals
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

const useDashboardContext = () => useContext(DashboardContext);
export { DashboardContextProvider, useDashboardContext };

