import React from "react";
import '../App.css'
const GlobleTransaction = () => {
  return (
    <section className="section market" aria-label="market update" data-section>
      <div className="container">
        <div className="title-wrapper" style={{ textAlign: "center", width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: '1.5rem' }}>
          <h2 className="h2 section-title text-center">Global Transactions</h2>

          {/* <a href="#" className="btn-link">
            See All Coins
          </a> */}
        </div>

        <div className="market-tab">

          <div className="company-row">
            <div className="company-col company-col-left">
              <h3>Overview</h3>
              <p>Welcome to FX NEX, the platform that transforms traditional payments into fast, flexible, and low-fee global transactions using cutting-edge blockchain technology. Join us on a journey towards a cashless future and financial freedom.</p>
            </div>
            <div className="company-col company-col-right">
              <img src="images/coinBook-removebg-preview.png" alt="" />
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default GlobleTransaction;
