import React from 'react'
import { BASEIMAGEURL, useAppContext } from '../Context/AppContext'

const ViewProfile = ({ isShow, onClose }) => {
    const { localUser } = useAppContext();
    console.log(localUser);
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            {localUser?.isApproved == "APPROVED" ? <div   className='viewProfileBox'
                style={{
                    height: "auto",
                    
                    flexDirection: 'column',
                    position: 'relative',
                   
                }}
            >
                <div style={{ paddingBlock: 10, width: '80%' }}>
                    <h3

                    style={{
                        padding:"10px",
                        background:"#161445",
                        borderRadius:"10px",
                        fontSize: 20,
                        fontWeight: "400", color: "white"
                    }}>Your Profile</h3></div>
                <div style={{
                    position: 'absolute',
                    right: 10,
                    top: 10
                }}>
                    <i className='bx bx-x' style={{
                        fontSize: 24,
                        color: "#111",
                        fontWeight: 'bold'
                    }} onClick={() => onClose()} />
                </div>
                
                <div style={{ marginLeft: 10, width: '80%' }}>
                    <p style={{fontSize:"15px"}}  >Name: {localUser?.username.toUpperCase()}</p>
                    <p  style={{fontSize:"15px" , marginTop:"10px"}}   >Email: {localUser?.email}</p>
                    <p style={{fontSize:"15px" ,marginTop:"10px"}} >Country: {localUser?.country}</p>
                     
                    <div style={{ paddingBlock: 10, width: '100%' }}>
                    <h3

                    style={{
                        padding:"10px",
                        background:"#161445",
                        borderRadius:"10px",
                        fontSize: 20,
                        fontWeight: "400", color: "white"
                    }}>National Identity</h3></div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>
                       
                        <div   >
                            <p  style={{fontSize:"15px", marginBottom:"10px" }} >Id Card Front</p>
                            <img
                                style={{
                                    width: "120px",
                                    height: '115px',
                                    borderRadius: '10px',
                                    background:"#dadada",
                                    padding:"5px"
                                }}
                                src={BASEIMAGEURL + localUser?.cnic?.front} alt="" />
                        </div>
                        <div style={{ width: 20 }}></div>
                        <div>
                            <p style={{fontSize:"15px",marginBottom:"10px"  }} > Id Card Back</p>
                            <img
                                style={{
                                    width: "120px",
                                    height: '115px',
                                    borderRadius: '10px',
                                    background:"#dadada",
                                    padding:"5px"
                                }}
                                src={BASEIMAGEURL + localUser?.cnic?.back} alt="" />
                        </div>
                    </div>
                </div>
            </div> : localUser?.isApproved == "REJECT" ?
                <div className='viewProfileBox'
                    style={{
                        height: "auto",
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                >

                    <div style={{ paddingBlock: 10, width: '80%' }}><p

                        style={{
                            fontSize: 20,
                            fontWeight: "bold", color: "#111"
                        }}>Reject</p></div>
                    <div style={{
                        position: 'absolute',
                        right: 10,
                        top: 10
                    }}>
                        <i className='bx bx-x' style={{
                            fontSize: 24,
                            color: "#111",
                            fontWeight: 'bold'
                        }} onClick={() => onClose()} />
                    </div>
                    <div style={{ marginLeft: 10, width: '80%', paddingBottom: 10 }}>
                        <p>Your account has been rejected please contact  support to active your account.<br />
                        </p>
                    </div>
                </div>
                :
                <div className='viewProfileBox'
                    style={{
                        

                        height: "auto",
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                >

                    <div style={{ paddingBlock: 10, width: '80%' }}><p
 
                        style={{
                            fontSize: 20,
                            marginLeft: 5,
                            fontWeight: "bold", color: "#111"
                        }}>Approval Pending</p></div>
                    <div style={{
                        position: 'absolute',
                        right: 10,
                        top: 10
                    }}>
                        <i className='bx bx-x' style={{
                            fontSize: 24,
                            color: "#111",
                            fontWeight: 'bold'
                        }} onClick={() => onClose()} />
                    </div>
                    <div style={{ marginLeft: 10, width: '80%', paddingBottom: 10 }}>
                        <p>Your Account is Under Process To Approve,
                            It May Take 24 Hours <br />
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}

export default ViewProfile