import React from 'react'

function TotalCards( {title , numbers} ) {
  return (
    
    <div style={{ marginRight:"20px", height:"200px",width:"250px",background:"white",boxShadow:"5px 10px #888888" ,borderRadius:"10px" ,display:"flex",flexDirection:"column",alignItems:"center",alignContent:"center",justifyContent:"center" }} >
    <h2 style={{marginTop:"20px"}} > {title}</h2>
    <h1 style={{}} > {numbers}</h1>
     
    </div>
  
  )
}

export default TotalCards