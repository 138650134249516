import React, { useEffect, useState } from 'react'
import { BaseApi, BASEIMAGEURL, useAppContext } from '../../Context/AppContext';
import ViewDeposit from '../Components/ViewDeposit/ViewDeposit';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

const WithDraw = () => {
    const { allWithdraw } = useAppContext();
    const [isView, setIsView] = useState(false);
    const [user, setUser] = useState(null);

    const [searchUser, setSearchUser] = useState(allWithdraw);
    useEffect(() => {
        setSearchUser(allWithdraw);
    }, [allWithdraw]);

    const search = (text) => {
        if (text.length > 0) {
            const search = allWithdraw.filter(
                (e) =>
                    (e?.user?.username.toLowerCase().includes(text.toLowerCase()) ||
                        e.user?.email.toLowerCase().includes(text.toLowerCase())) &&
                    e
            );
            // console.log(search);
            if (search.length > 0) {
                setSearchUser(search);
            } else {
                setSearchUser([]);
            }
        } else {
            setSearchUser(allWithdraw);
        }
    };



    const approvedDepositOrReject = async (id, status) => {
        try {
            const response = await BaseApi.put("/withdraw/approvedOrReject", {
                id: id,
                status: status,
            });
            if (response.status == 200) {
                toast.success("Withdraw " + status + " Success");
                window.location.reload();
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (
                    error.response.status == 400 ||
                    error.response.status == 404 ||
                    error.response.status == 401
                ) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            }
        }
    };
    return (
        <div>
            <div className="search-box">
                <input
                    placeholder="Search here"
                    onChange={(e) => search(e.target.value)}
                />
                {/* <button>Add New</button> */}
            </div>
            <div className="table-box">
                <table className=" market-table">
                    <thead className="table-head">
                        <tr className="table-row table-title">
                            <th className="table-heading" scope="col">
                                #
                            </th>
                            <th className="table-heading" scope="col">
                                UserName
                            </th>

                            <th className="table-heading" scope="col">
                                Amount
                            </th>
                            <th className="table-heading" scope="col">
                                Account Number
                            </th>
                            <th className="table-heading" scope="col">
                                Account Name
                            </th>
                            <th className="table-heading" scope="col">
                                Payment Method
                            </th>
                            <th className="table-heading" scope="col">
                                Status
                            </th>
                            <th className="table-heading" scope="col">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {searchUser == null ? (
                            <p>Please Wait...</p>
                        ) : searchUser.length > 0 ? (
                            searchUser?.map((d, k) => {
                                return (
                                    <tr className="table-row" key={k}>
                                        <td>{k + 1}</td>
                                        <td>{d?.user?.username}</td>
                                        <td>{d?.amount}$</td>
                                        <td>{d?.accountNumber}</td>
                                        <td>{d?.accountName}</td>
                                        <td>{d?.coins?.type}</td>
                                        <td>{d?.status ? "Approved" : "Pending"}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <i
                                                onClick={async () => {
                                                    await approvedDepositOrReject(d?._id, true)
                                                }}
                                                className="bx bx-check"
                                                style={{ cursor: "pointer", background: "green", padding: 5, borderRadius: 100 }}
                                            />
                                            <i

                                                onClick={async () => {
                                                    await approvedDepositOrReject(d?._id, false)
                                                }}
                                                className="bx bx-trash"
                                                style={{ cursor: "pointer", marginInline: 10, background: "red", padding: 5, borderRadius: 100 }}
                                            />

                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <p>No Data Founded</p>
                        )}
                    </tbody>
                </table>
            </div>
            {/* <ViewDeposit isShow={isView} onClose={() => setIsView(false)} data={user} /> */}
        </div>
    );
}

export default WithDraw