import React from "react";
 import '../App.css'
const Market = () => {
  return (
    <section className="section market" aria-label="market update" data-section>
      <div className="container">
        <div className="title-wrapper" style={{ textAlign: "center", width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: '1.5rem' }}>
          <h2 className="h2 section-title text-center">Our Company</h2>

          {/* <a href="#" className="btn-link">
            See All Coins
          </a> */}
        </div>

        <div className="market-tab">

          <div className="company-row">
            <div className="company-col company-col-left">
              <h3>Overview</h3>
              <p>FX NEX, is the ultimate alternative to traditional payment and remittance networks. By using blockchain technology, we enable extremely fast global payments with minimal fees, making micro-payments worldwide perfectly viable.</p>
            </div>
            <div className="company-col company-col-right">
              <img src="images/coinBTC-removebg-preview.png" alt="" />
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Market;
