import React, { useEffect, useState } from "react";
import { BaseApi, useAppContext } from "../../Context/AppContext";
import ViewUser from "../Components/ViewUser/ViewUser";
import toast from "react-hot-toast";

const Users = () => {
  const { allUsers } = useAppContext();
  const [isView, setIsView] = useState(false);
  const [user, setUser] = useState(null);

  const [searchUser, setSearchUser] = useState(allUsers);
  useEffect(() => {
    setSearchUser(allUsers);
  }, [allUsers]);

  const search = (text) => {
    if (text.length > 0) {
      const search = allUsers.filter(
        (e) =>
          (e.username.toLowerCase().includes(text.toLowerCase()) ||
            e.email.toLowerCase().includes(text.toLowerCase()) ||
            e.country.toLowerCase().includes(text.toLowerCase())) &&
          e
      );
      console.log(search);
      if (search.length > 0) {
        setSearchUser(search);
      } else {
        setSearchUser([]);
      }
    } else {
      setSearchUser(allUsers);
    }
  };

  return (
    <div>
      <div className="search-box">
        <input
          placeholder="Search here"
          onChange={(e) => search(e.target.value)}
        />
        <button
          onClick={() => setIsView(true)}
          style={{ display: 'block' }}>Add Profit</button>
      </div>
      <div className="table-box">
        <table className=" market-table">
          <thead className="table-head">
            <tr className="table-row table-title">
              <th className="table-heading" scope="col">
                #
              </th>
              <th className="table-heading" scope="col">
                Name
              </th>
              <th className="table-heading" scope="col">
                Email
              </th>
              <th className="table-heading" scope="col">
                Verify
              </th>
              <th className="table-heading" scope="col">
                Status
              </th>
              <th className="table-heading" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {searchUser == null ? (
              <p>Please Wait...</p>
            ) : searchUser.length > 0 ? (
              searchUser?.map((v, i) => {
                return (
                  <tr className="table-row" key={i}>
                    <td>{i + 1}</td>
                    <td>{v?.username}</td>
                    <td>{v?.email}</td>
                    <td>{v?.isEmailVerified && v?.isnumberVerified && "Verified" || "Not Verify"}</td>
                    <td>{v?.status ? "Active" : "InActive"}</td>
                    <td>
                      <i
                        onClick={() => {
                          // setUser(v);
                          // setIsView(true);
                        }}
                        className="bx bx-edit"
                        style={{ cursor: "pointer" }}
                      />
                      <i
                        className="bx bx-trash"
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <p>No Data Founded</p>
            )}
          </tbody>
        </table>
      </div>
      {/* <ViewUser isShow={isView} onClose={() => setIsView(false)} data={user} /> */}
      <AddProfit isShow={isView} onClose={() => setIsView(false)} />
    </div>
  );
};




const AddProfit = ({ isShow, onClose }) => {
  const { allPlans } = useAppContext();
  const [loader, setLoader] = useState(false);

  const [isCustom, setIsCustom] = useState(false);
  const [users, setUsers] = useState(null);
  const [planId, setPlanId] = useState('');
  const [userIds, setUserIds] = useState(null);
  const [profit, setProfit] = useState('');


  const onGetUser = async (id) => {
    setPlanId(id);
    setIsCustom(id == "67486e6bb957af1aac211ed4")
    await BaseApi.get(`/plan/getUserByPlan/${id}`)
      .then((res) => {
        console.log(res.data);
        // if (res?.data?.data?.length > 0) {
        setUsers(res?.data?.data);
        // }
      })
      .catch((er) => {
        console.log(er);
      })
  }


  const onSelectUser = (id) => {
    const userIds = id == "all" ? users?.map((u) => u?.user?._id) : [id];
    setUserIds(userIds)
  }


  const addProfit = async () => {
    setLoader(true);
    await BaseApi.post("/user/addProfit", { "planId": planId, userIds: userIds, profit: profit })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Add Profit Success");
          window.location.reload();
        }
      }).catch(er => {
        console.log(er);
      });
    setLoader(false);
  }

  const onPlanSelect = (id) => {
    const allUser = users?.filter(v => v?.planName == id);
    setUsers(allUser);
  }
  return (
    <div className={`model-box ${isShow ? "show" : "hide"}`}>
      <div className='loginModel'
        style={{
          height: "auto"
        }}
      >

        <div className='modal-header'>
          <h4>{"Add Profit"}</h4>
        </div>
        <div className='modal-body'>
          <div className="form-group">
            <label htmlFor="">Plans</label>
            <div className="field" style={{ paddingInline: 10 }}>
              <select
                onChange={(e) => onGetUser(e.target.value)}
                style={{ width: '100%', border: 'nones', outline: 'none', background: "transparent" }}>
                <option>--Select Plan--</option>
                {
                  allPlans?.map((v, k) => {
                    return <option value={v?._id}>Invest: {v?.invest?.from}$ Type: ({v?.type}) Profit: {v?.profit?.from}/{v?.profit?.to}% </option>
                  })
                }
              </select>
            </div>
          </div>

          {isCustom && <div className="form-group">
            <label htmlFor="">Plan Type</label>
            <div className="field" style={{ paddingInline: 10 }}>
              <select
                onChange={(e) => onPlanSelect(e.target.value)}
                style={{ width: '100%', border: 'none', outline: 'none', background: "transparent" }}>
                <option>--Select Type Plan--</option>
                {
                  ["Monthly", "Yearly"]?.map((v, k) => {
                    return <option value={v} key={k}>{v}</option>
                  })
                }
              </select>
            </div>
          </div>}

          <div className="form-group">
            <label htmlFor="">Users</label>
            <div className="field" style={{ paddingInline: 10 }}>
              <select
                onChange={(e) => onSelectUser(e.target.value)}
                style={{ width: '100%', border: 'none', outline: 'none', background: "transparent" }}>
                <option>--Select Users--</option>
                <option value={"all"}>All</option>
                {
                  users?.map((v, k) => {
                    return <option key={k} value={v?.user?._id}>{v?.user?.username} ({v?.planName && v?.planName != '' ? v?.planName : v?.plan?.type}) {v?.amount && v?.amount != 0 ? v?.amount : v?.plan?.invest?.from}$</option>
                  })
                }
              </select>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Profit (%)</label>
            <div className="field" style={{ paddingInline: 4 }}>
              <input type="number"
                onChange={(e) => { setProfit(e.target.value) }}
                value={profit}
                placeholder="Enter Profit %" style={{ width: '100%', border: 'none', outline: 'none', color: "#111", background: "transparent" }} />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button onClick={() => {
            onClose();
            setUsers(null);
            setProfit('');
            setPlanId("");

          }}>Cancel</button>
          <button style={{ width: 120 }} onClick={() => {
            // isApprovedOrReject("APPROVED");
            addProfit();
          }}>{loader ? "Please Wait.." : "Add Profit"}</button>
        </div>
      </div>
    </div>
  )
}

export default Users;
