import React, { useState ,useEffect} from "react";
import { BASEIMAGEURL, useAppContext } from "../../Context/AppContext";
import ViewUserPlan from "../Components/ViewUserPlan/ViewUserPlan";

const UserBuyPlan = () => {
  const { userPlan } = useAppContext();
  const [isShow, setIsShow] = useState(false);
  const [plan, setPlan] = useState(null);

  const [search, setSearch] = useState(userPlan);

  useEffect(() => {
    setSearch(userPlan);
  }, [userPlan]);

  const onSearch = (text) => {
    if (text.length > 0) {
      const sear = userPlan.filter((e) =>
        e.user.username.toLowerCase().includes(text.toLowerCase())
      );
      console.log(sear);
      if (sear.length > 0) {
        setSearch(sear);
      } else {
        setSearch([]);
      }
    } else {
      setSearch(userPlan);
    }
  };

  return (
    <div>
      <div className="search-box">
        <input
          placeholder="Search here"
          onChange={(e) => onSearch(e.target.value)}
        />
        {/* <button
        onClick={()=>{
          setIsShow(true);
        }}
        >Add New</button> */}
      </div>
      <div className="table-box">
        <table className=" market-table">
          <thead className="table-head">
            <tr className="table-row table-title">
              <th className="table-heading" scope="col">
                #
              </th>
              <th className="table-heading" scope="col">
                Invest
              </th>
              <th className="table-heading" scope="col">
                Profit
              </th>
              <th className="table-heading" scope="col">
                User
              </th>
              <th className="table-heading" scope="col">
                Image
              </th>
              <th className="table-heading" scope="col">
                Status
              </th>
              <th className="table-heading" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {search == null ? (
              <p>Please Wait...</p>
            ) : search.length > 0 ? (
              search?.map((v, i) => {
                return (
                  <tr className="table-row" key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {v?.plan?.invest?.from + " to " + v?.plan?.invest?.to}
                    </td>
                    <td>
                      {v?.plan?.profit?.from + " to " + v?.plan?.profit?.to}
                    </td>
                    <td>{v?.user?.username}</td>
                    <td>
                      <img
                        style={{ width: 50, height: 50, borderRadius: 10 }}
                        src={BASEIMAGEURL + v?.image}
                        alt=""
                      />
                    </td>
                    <td>{v.isApproved}</td>
                    <td>
                      <i
                        onClick={() => {
                          setPlan(v);
                          setIsShow(true);
                        }}
                        className="bx bx-edit"
                        style={{ cursor: "pointer" }}
                      />
                      {/* <i className='bx bx-trash'/> */}
                    </td>
                  </tr>
                );
              })
            ) : (
              <p>No Data Founded</p>
            )}
          </tbody>
        </table>
      </div>
      <ViewUserPlan
        plan={plan}
        isShow={isShow}
        onClose={() => setIsShow(false)}
      />
    </div>
  );
};

export default UserBuyPlan;
