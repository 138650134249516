import React, { useEffect, useState } from 'react'
import { useAppContext } from '../Context/AppContext'

const UserHistory = () => {
  const {allUserTransactions} = useAppContext();

  const [search,setSearch] = useState(allUserTransactions);

  useEffect(()=>{
    setSearch(allUserTransactions)
  },[allUserTransactions])


  const onSearch =(text)=>{
    if(text.length>0){
       const sear = allUserTransactions.filter(e=>e.user.username.toLowerCase().includes(text.toLowerCase()));
       console.log(sear);
       if(sear.length>0){
        setSearch(sear);
       }else{
        setSearch([]);
       }
    }else{
      setSearch(allUserTransactions);
    }
  }

  return (
    <div  style={{display:'flex',alignItems:"center",flexDirection:'column'}}>
      <div  style={{visibility:"hidden"}} className='search-box'>
        <input placeholder='Search here' 
        onChange={(e)=>{onSearch(e.target.value)}}
        />
        <button>Add New</button>
      </div>
      {/* <div>
        <h3>History</h3>
      </div> */}
      <div style={{marginTop:"20vh",marginBottom:"20vh",width:"80%"}}  className='table-box'>
        <table className=' market-table'>
          <thead className='table-head'>
            <tr className='table-row table-title'>
              <th className='table-heading' scope='col'>#</th>
              <th className='table-heading' scope='col'>Name</th>
              <th className='table-heading' scope='col'>Type</th>
              <th className='table-heading' scope='col'>Date</th>
              <th className='table-heading' scope='col'>Budget</th>
              <th className='table-heading' scope='col'>Profit</th>
              <th className='table-heading' scope='col'>Status</th>
              {/* <th className='table-heading' scope='col'>Action</th> */}
            </tr>
          </thead>
          <tbody className='table-body '>
            {
              search == null ? <p>Please Wait...</p> :
                search.length > 0 ?
                  search?.map((v, i) => {
                    console.log(v);
                    return (
                      <tr className='table-row' key={i}>
                        <td>{i + 1}</td>
                        <td>{v?.user.username}</td>
                        <td>{v?.type}</td>
                        <td>{v?.date.split("T")[0]}</td>
                        <td>{v?.despositeBudget}</td>
                        <td>{v?.profitBudget}</td>
                        <td>{v.status ? "Active" : "InActive"}</td>
                        {/* <td>
                          <i
                            onClick={() => {
                              // setUser(v);
                              // setIsView(true)
                            }}
                            className='bx bx-edit' style={{ cursor: "pointer" }} />
                          <i className='bx bx-trash' style={{ cursor: "pointer" }} />

                        </td> */}
                      </tr>
                    );
                  })
                  : <p>No Data Founded</p>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UserHistory