import React, { useEffect } from "react";
import "./style.css";
import SideBar from "./Components/SideBar/SideBar";
import Header from "./Components/Header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppContext } from "../Context/AppContext";
import AdminLogin from "./Screens/AdminLogin";
const Index = () => {
  const {
    getAllUser,
    getAllAdminPlan,
    getAllAdminCoin,
    getUserPlanAdmin,
    getAllTransactionAdmin,
    localAdmin,
  } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    getAllUser();
    getAllAdminPlan();
    getAllAdminCoin();
    getUserPlanAdmin();
    getAllTransactionAdmin();
    // if (localAdmin != null) {
    //   navigate("/admin",{replace:true});
    // }else if(localAdmin==null){
    //   navigate("/admin/login",{replace:true});
    // }
  }, []);

  if (localAdmin != null) {
    return (
      <div className="main-box">
        {localAdmin !== null && (
          <div className="sideBarBox">
            <SideBar />
          </div>
        )}
        <div className="content">
          {localAdmin != null && <Header />}
          <div
            style={{
              padding: 10,
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <AdminLogin />
  }
};

export default Index;
