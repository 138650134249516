import React from "react";

const Instruction = () => {
  return (
    <section
      className="section instruction"
      aria-label="instruction"
      data-section
    >
      <div className="container">
        <h2 className="h2 section-title">How It Work</h2>

        <p className="section-text">
        Experience Profitable Growth in Just a Few Simple Steps – Follow Along for Success!
        </p>

        <ul className="instruction-list">
          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="./assets/images/instruction-1.png"
                  width="96"
                  height="96"
                  loading="lazy"
                  alt="Step 1"
                  className="img"
                />
              </figure>

              <p className="card-subtitle">Step 1</p>

              <h3 className="h3 card-title">Register</h3>

              <p className="card-text">
              Register on Our Site for a Seamless Verification Process and Unlock Exclusive Benefits!

              </p>
            </div>
          </li>

          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="./assets/images/instruction-2.png"
                  width="96"
                  height="96"
                  loading="lazy"
                  alt="Step 2"
                  className="img"
                />
              </figure>

              <p className="card-subtitle">Step 2</p>

              <h3 className="h3 card-title">Connect Wallet</h3>

              <p className="card-text">
              Connect Your Wallet, Invest with Ease, and Enjoy Hassle-free Returns on Your Investment!.
              </p>
            </div>
          </li>

          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="./assets/images/instruction-3.png"
                  width="96"
                  height="96"
                  loading="lazy"
                  alt="Step 3"
                  className="img"
                />
              </figure>

              <p className="card-subtitle">Step 3</p>

              <h3 className="h3 card-title">Start Trading</h3>

              <p className="card-text">
              Automated Excellence: Your Ratings Generated Instantly for a Seamless Experience!

              </p>
            </div>
          </li>

          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="./assets/images/instruction-4.png"
                  width="96"
                  height="96"
                  loading="lazy"
                  alt="Step 4"
                  className="img"
                />
              </figure>

              <p className="card-subtitle">Step 4</p>

              <h3 className="h3 card-title">Earn Money</h3>

              <p className="card-text">
              Stay Informed Seamlessly: Your Profits Update Automatically Everyday for Effortless Monitoring!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Instruction;
