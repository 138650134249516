import React, { useState } from 'react'
import AddCoinAddress from '../Components/AddCoinAddress/AddCoinAddress';
import { useAppContext } from '../../Context/AppContext';

const CoinAddress = () => {
  const [isShow, setIsShow] = useState(false);
  const { allCoins } = useAppContext();
  return (
    <div>
      <div className='search-box'>
        <input placeholder='Search here' />
        <button

          onClick={() => { setIsShow(true) }}
        >Add New</button>
      </div>
      <div className='table-box'>
        <table className=' market-table'>
          <thead className='table-head'>
            <tr className='table-row table-title'>
              <th className='table-heading' scope='col'>#</th>
              <th className='table-heading' scope='col'>Name</th>
              <th className='table-heading' scope='col'>Account Number</th>
              <th className='table-heading' scope='col'>Account Type</th>
              <th className='table-heading' scope='col'>Status</th>
              <th className='table-heading' scope='col'>Action</th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {
              allCoins == null ? <p>Please Wait...</p> :
                allCoins.length > 0 ?
                  allCoins?.map((v, i) => {
                    return (
                      <tr className='table-row' style={{ paddingInline: 10 }} key={i}>
                        <td style={{ paddingLeft: 10, }}>{i + 1}</td>
                        <td>{v?.accountName}</td>
                        <td>{v?.accountNumber}</td>
                        <td>{v?.type == "BANK" || v?.type == "CRYPTO" ? v?.bankAndCoinName : v?.type}</td>
                        <td>{v.status ? "Active" : "InActive"}</td>
                        <td>
                          <i className='bx bx-edit' />
                          <i className='bx bx-trash' />
                        </td>
                      </tr>
                    );
                  })
                  : <p>No Data Founded</p>
            }
          </tbody>
        </table>
      </div>
      <AddCoinAddress isShow={isShow} onClose={() => setIsShow(false)} />
    </div>
  )
}

export default CoinAddress