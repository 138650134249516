import React, { useEffect, useState } from 'react'
import { BaseApi, useAppContext } from '../../Context/AppContext';
import { BiSolidImage } from 'react-icons/bi';
import HistoryTable from './Tables/HistoryTable';
import country from './../../countries.json';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import firebase from 'firebase/compat/app';
import toast from 'react-hot-toast';
const Profile = () => {
  return (
    <div>
      <Tabs />
    </div>
  )
}



const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "Tab1":
        return <ShowProfile />;
      case "Tab2":
        return <AccountVerification />;
      case "Tab3":
        return <HistoryTable />;
      case "Tab4":
        return <ForgotPassword />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-buttons">
        <button
          className={activeTab === "Tab1" ? "active" : ""}
          onClick={() => setActiveTab("Tab1")}
        >
          Account Info
        </button>
        <button
          className={activeTab === "Tab2" ? "active" : ""}
          onClick={() => setActiveTab("Tab2")}
        >
          Verification
        </button>
        <button
          className={activeTab === "Tab3" ? "active" : ""}
          onClick={() => setActiveTab("Tab3")}
        >
          History
        </button>
        <button
          className={activeTab === "Tab4" ? "active" : ""}
          onClick={() => setActiveTab("Tab4")}
        >
          Change Password
        </button>
      </div>
      <div className=" referralBox">{renderContent()}</div>
    </div>
  );
}


const ShowProfile = () => {
  const baseUrl = "http://localhost:3000/referral";
  const { userInfo } = useAppContext();

  const [state, setState] = useState({
    username: '',
    number: '',
    country: 'Select Country'
  });


  useEffect(() => {
    console.log(userInfo);
    if (userInfo) {
      setState((prevState) => ({
        ...prevState,
        username: userInfo?.username,
      }));
      setState((prevState) => ({
        ...prevState,
        country: userInfo?.country,
      }));

    }
  }, [])

  const onSelectCountry = async (data) => {
    console.log(data);
    setState((prevState) => ({
      ...prevState,
      country: data,
    }));
  }



  const updateInfo = async () => {

    await BaseApi.put(`/user/updateInfo/${userInfo?._id}`, { username: state.username, country: state.country })
      .then((res) => {
        toast.success("Update Info Success");
        window.location.reload();
      }).catch((er) => {
        if (er.status == 400 || er.status == 404) {
          toast.error(`${er.response?.data['message']}`)
        }
      })

  };







  return (
    <div>
      <div className='form-row'>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Username</label>
            <input type="text" value={state.username}
              onChange={(d) => {
                setState((prevState) => ({
                  ...prevState,
                  username: d.target.value,
                }));

              }}
              id="account-number" placeholder="Enter Username" />
          </div>
          <div class="input-container">
            <label for="account-number">Email</label>
            <input type="text" id="account-number" value={userInfo?.email} placeholder="Enter Email" />
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Number</label>
            <input type="tel"

              id="account-number" value={userInfo?.number}
              // onChange={(d) => {
              //   setState((prevState) => ({
              //     ...prevState,
              //     number: d.target.value,
              //   }));

              // }}
              placeholder="Enter Number" />

            <div id="recaptcha-container"></div>

          </div>
          <div class="input-container">
            <div class="dropdown">
              <button class="dropdown-btn">{state.country}</button>
              <div class="dropdown-content" style={{ height: "40vh", overflow: "scroll", overflowX: "hidden" }}>
                {country.map((b, i) => {
                  return (
                    <div onClick={() => {
                      onSelectCountry(b.country);
                    }} key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.country} ({b.code}) <img src={b.flag} alt="Check" /></div>
                  )
                })
                }
              </div>
            </div>
            {/* <label for="account-number">Country</label>
            <input type="text" id="account-number" value={state.country}
              onChange={(d) => {
                setState((prevState) => ({
                  ...prevState,
                  country: d.target.value,
                }));

              }} placeholder="Enter Country" /> */}
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Referral Code</label>
            <input type="text" id="account-number" value={`${baseUrl}?ref=${encodeURIComponent(userInfo?.referralCode)}`} placeholder="Enter referral code" />
          </div>
          <div class="input-container">
            {/* <label for="account-number">Email</label>
            <input type="text" id="account-number" placeholder="Enter Email" /> */}
          </div>
        </div>

        <div className='btn-row'>
          <button onClick={updateInfo}>Update</button>
        </div>
      </div>
    </div>
  );
}

const ForgotPassword = () => {
  const { userInfo } = useAppContext();
  const [state, setState] = useState({
    password: '',
    newPassword: '',
    cPassword: ''
  });

  const [errors, setErrors] = useState({
    password: '',
    newPassword: '',
    cPassword: ''
  });

  const onUpdatePassword = async () => {
    const { password, newPassword, cPassword } = state;

    // Validate password
    if (password.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Current password is required' }));
      toast.error('Error: Current password is required');
      return;
    }

    // Validate new password
    if (newPassword.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, newPassword: 'New password is required' }));
      toast.error('Error: New password is required');
      return;
    }

    // Validate new password length
    if (newPassword.length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, newPassword: 'New password must be at least 8 characters long' }));
      toast.error('Error: New password must be at least 8 characters long');
      return;
    }

    // Validate confirm password
    if (cPassword.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, cPassword: 'Confirm password is required' }));
      toast.error('Error: Confirm password is required');
      return;
    }

    // Validate confirm password match
    if (newPassword !== cPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, cPassword: 'Passwords do not match' }));
      toast.error('Error: Passwords do not match');
      return;
    }

    // If all validations pass, update password
    try {
      // Your password update logic here
    } catch (error) {
      console.error(error);
      toast.error('Error updating password: ' + error.message);
    }
  };
  return (
    <div>
      <div className='form-row'>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Email</label>
            <input value={userInfo?.email} type="text" id="account-number" placeholder="Enter Email" />
          </div>
          <div class="input-container">
            <label for="account-number">Password</label>
            <input
              value={state.password}
              onChange={(e) => {
                setState(p => ({
                  ...p,
                  password: e.target.value
                }))
              }}

              type="password" id="account-numer" placeholder="Enter Old Password" />
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">New Password</label>
            <input
              value={state.newPassword}
              onChange={(e) => {
                setState(p => ({
                  ...p,
                  newPassword: e.target.value
                }))
              }}



              type="password" id="account-number" placeholder="Enter New Password" />
          </div>
          <div class="input-container">
            <label for="account-number">Confirm Password</label>
            <input
              value={state.cPassword}
              onChange={(e) => {
                setState(p => ({
                  ...p,
                  cPassword: e.target.value
                }))
              }}

              type="password" id="account-nmber" placeholder="Enter Confirm Password" />
          </div>
        </div>
        <div className='btn-row'>
          <button onClick={onUpdatePassword}>Update</button>
        </div>
      </div>
    </div>
  );
}


const AccountVerification = () => {
  const { userInfo } = useAppContext();

  const [state, setState] = useState({
    number: '',
    otp: '',
  });

  const [state1, setState1] = useState({
    email: '',
    otp: '',
  });


  useEffect(() => {
    console.log(userInfo);
    if (userInfo) {
      setState((prevState) => ({
        ...prevState,
        number: userInfo?.number || '',
      }));

      if (userInfo?.isEmailVerified) {
        setState1({
          otp: userInfo.otpMailCode || '',
        });
      }

      if (userInfo?.isnumberVerified) {

        setState((prevState) => ({
          ...prevState,
          otp: userInfo.otpNumberCode || '',
        }));
      }
    }
  }, [userInfo]);






  const sendOtp = async () => {
    if (!/^\+\d{10,15}$/.test(state.number)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    await BaseApi.post(`/user/sendNumberOtp/${userInfo?._id}`)
      .then((res) => {
        toast.success("Sent OTP Code to your Email Please Check The Email.");
      }).catch((er) => {
        console.log(er);
      })

  };


  const verifyOtp = async () => {
    if (userInfo?.isnumberVerified) {
      return;
    }
    if (state.otp == "") {
      toast.error("Please enter a valid OTP code.");
      return;
    }

    await BaseApi.put(`/user/updateNumberOtp/${userInfo?._id}`, { number: state.number, otp: state.otp })
      .then((res) => {
        toast.success("OTP Verified Success");
        window.location.reload();
      }).catch((er) => {
        if (er.status == 400) {
          toast.error(`${er.response?.data['message']}`)
        }
      })

  };



  const sendOtp1 = async () => {
    await BaseApi.post(`/user/sendMailCode/${userInfo?._id}`)
      .then((res) => {
        toast.success("Sent Verification Code to your Email Please Check The Email.");
      }).catch((er) => {
        console.log(er);
      })

  };


  const verifyOtp1 = async () => {
    if (userInfo?.isEmailVerified) {
      return;
    }
    if (state1.otp == "") {
      toast.error("Please enter a valid Verification code.");
      return;
    }

    await BaseApi.put(`/user/updateMailOtp/${userInfo?._id}`, { otp: state1.otp })
      .then((res) => {
        toast.success("OTP Verified Success");
        window.location.reload();
      }).catch((er) => {
        // console.log(er.status);
        if (er.status == 400) {
          toast.error(`${er.response?.data['message']}`)
        }
      })

  };

  return (
    <div className='accountVerify'>
      <div className='form-row'>
        {/* <div className='input-row'> */}
        <div class="input-container">
          <label for="account-number">Email</label>
          <input type="text" id="account-number"
            disabled={userInfo?.isEmailVerified}

            value={userInfo?.email} placeholder="Enter Email" />
        </div>
        <div class="input-container">
          <label for="account-number">OTP Code</label>
          <input type="number"
            value={state1.otp}
            onChange={(d) => {
              setState1((prevState) => ({
                ...prevState,
                otp: d.target.value,
              }));

            }}
            disabled={userInfo?.isEmailVerified}
            id="account-number" placeholder="Enter OTP Code" />
        </div>
        {/* </div> */}

        <div className='btn-row'>
          <button onClick={sendOtp1}>{userInfo?.isnumberVerified ? "Verified" : "Send Code"}</button>
          <div style={{ width: 13 }}></div>
          <button onClick={verifyOtp1}>Update</button>
        </div>
        {/* <div className='input-row' style={{ marginTop: 30 }}> */}
        <div class="input-container">
          <label for="account-number">Number</label>
          <input type="text" id="account-number"
            value={state.number}
            disabled={userInfo?.isnumberVerified}
            onChange={(d) => {
              setState((prevState) => ({
                ...prevState,
                number: d.target.value,
              }));
            }}
            placeholder="Enter Number" />
        </div>
        <div class="input-container">
          <label for="account-number">Otp Code</label>
          <input type="number" id="account-number"
            value={state.otp}
            onChange={(d) => {
              setState((prevState) => ({
                ...prevState,
                otp: d.target.value,
              }));

            }}
            disabled={userInfo?.isnumberVerified}
            placeholder="Enter Otp Code" />

        </div>
        {/* </div> */}
        <div className='btn-row'>
          <button onClick={sendOtp}>{userInfo?.isnumberVerified ? "Verified" : "Send Code"}</button>
          <div style={{ width: 13 }}></div>
          <button onClick={verifyOtp}>Update</button>
        </div>
      </div>
      <div className='form-row ' style={{ paddingInline: '2.3rem' }}>
        <div className='cinBox'>
          <div class="image-upload">
            <label for="file-input">
              <BiSolidImage className='upload-icon' />
              {/* <i class="upload-icon">&#x1F4F7;</i> */}
              <span>Upload Id Card Front</span>
            </label>
            <input id="file-input" type="file" accept="image/*" />
          </div>
          <div class="image-upload">
            <label for="file-input">
              <BiSolidImage className='upload-icon' />
              {/* <i class="upload-icon">&#x1F4F7;</i> */}
              <span>Upload Id Card Back</span>
            </label>
            <input id="file-input" type="file" accept="image/*" />
          </div>

        </div>
        <div class="input-container" style={{ marginTop: 30 }}>
          <label for="account-number">Id Card Number</label>
          <input type="text" id="account-number" placeholder="Enter Id Card Number" />
        </div>

        <div className='btn-row' style={{ justifyContent: 'end' }}>
          <div style={{ width: 13 }}></div>
          <button>Update</button>
        </div>
      </div>
    </div>
  );
}

export default Profile