import React from 'react'
import { BASEIMAGEURL, useAppContext } from '../Context/AppContext'

const PlanView = ({ isShow, onClose }) => {
    const { buyPlan } = useAppContext();
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            {buyPlan?.isApproved == "APPROVED" ? <div className='viewProfileBox'
                style={{
                    height: "auto",
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <div style={{ paddingBlock: 10, width: '80%' }}><p

                    style={{
                        fontSize: 20,
                        padding: "08px",
                        borderRadius: "10px",
                        background: "#0b0a33",
                        fontWeight: "400", color: "white"
                    }}>View Plan</p></div>
                <div style={{
                    position: 'absolute',
                    right: 10,
                    top: 10
                }}>
                    <i className='bx bx-x' style={{
                        fontSize: 24,
                        color: "#111",
                        fontWeight: 'bold'
                    }} onClick={() => onClose()} />
                </div>
                <div style={{ marginLeft: 10, width: '80%' }}>
                    <p>Current Plan is invest {buyPlan?.plan?.invest?.from} to {buyPlan?.plan?.invest?.to} profit is {buyPlan?.plan?.profit?.from} to {buyPlan?.plan?.profit?.to} </p>
                    {/* <p>Name: {localUser?.username}</p>
                    <p>Email: {localUser?.email}</p>
                    <p>Country: {localUser?.country}</p> */}
                    <div style={{ height: '2rem' }}></div>
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>
                        <div>
                            <p>Id Card Front</p>
                            <img
                                style={{
                                    width: "124px",
                                    height: '115px',
                                    borderRadius: '10px',
                                }}
                                src={BASEIMAGEURL + localUser?.cnic?.front} alt="" />
                        </div>
                        <div style={{ width: 20 }}></div>
                        <div>
                            <p> Id Card Back</p>
                            <img
                                style={{
                                    width: "124px",
                                    height: '115px',
                                    borderRadius: '10px',
                                }}
                                src={BASEIMAGEURL + localUser?.cnic?.back} alt="" />
                        </div>
                    </div> */}
                </div>
            </div> : buyPlan?.isApproved == "REJECT" ?
                <div className='viewProfileBox'
                    style={{
                        height: "auto",
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                >

                    <div style={{ paddingBlock: 10, width: '80%' }}><p

                        style={{
                            fontSize: 20,
                            fontWeight: "bold", color: "#111"
                        }}>Reject</p></div>
                    <div style={{
                        position: 'absolute',
                        right: 10,
                        top: 10
                    }}>
                        <i className='bx bx-x' style={{
                            fontSize: 24,
                            color: "#111",
                            fontWeight: 'bold'
                        }} onClick={() => onClose()} />
                    </div>
                    <div style={{ marginLeft: 10, width: '80%', paddingBottom: 10 }}>
                        <p>Your Plan has been rejected please contact  support to active your account.<br />
                        </p>
                    </div>
                </div>
                :
                <div className='viewProfileBox'
                    style={{
                        height: "auto",
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                >

                    <div style={{ paddingBlock: 10, width: '80%' }}><p

                        style={{
                            fontSize: 20,
                            fontWeight: "bold", color: "#111"
                        }}>Approval Pending</p></div>
                    <div style={{
                        position: 'absolute',
                        right: 10,
                        top: 10
                    }}>
                        <i className='bx bx-x' style={{
                            fontSize: 24,
                            color: "#111",
                            fontWeight: 'bold'
                        }} onClick={() => onClose()} />
                    </div>
                    <div style={{ marginLeft: 10, width: '80%', paddingBottom: 10 }}>
                        <p>Your Plan is under process to approve,
                            its may tak 24 hours <br />
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}

export default PlanView