import React, { useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const { adminLogin } = useAppContext();
  const [isLoader, setIsLoader] = useState(false);
  const [email, setEmial] = useState("");
  const [password, setPassword] = useState("");
  const login = async () => {
    setIsLoader(true);
    await adminLogin({ email, password });
     window.location.reload()
    setIsLoader(false);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "30%",
          height: "auto",
          background: "#fff",
          borderRadius: 10,
          padding: 10,
        }}
      >
        <h3 style={{ marginBottom: 20 }}>Admin Login</h3>
        <div className="form-group">
          <label htmlFor="">Email</label>
          <div className="field">
            <input
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                border: "0px solid",
                outline: "none",
              }}
              placeholder="Enter Email"
              onChange={(e) => setEmial(e.target.value)}
              value={email}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">Password</label>
          <div className="field">
            <input
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                border: "0px solid",
                outline: "none",
              }}
              placeholder="Enter Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
        </div>
        <button
          style={{
            width: "100%",
            height: "40px",
            background: "#3772ff",
            borderRadius: "5px",
            color: "#fff",
            fontWeight: 600,
            marginInline: "5px",
            position: "relative",
          }}
          onClick={() => {
            login();
          }}
        >
          {isLoader ? "Please Wait..." : "Login"}
        </button>
      </div>
    </div>
  );
};

export default AdminLogin;
