import React from "react";
import '../App.css'
const OurMission = () => {
  return (
    <section className="section market" aria-label="market update" data-section>
      <div className="container">
        <div className="title-wrapper" style={{ textAlign: "center", width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: '1.5rem' }}>
          <h2 className="h2 section-title text-center">Our Mission & Vision</h2>

          {/* <a href="#" className="btn-link">
            See All Coins
          </a> */}
        </div>

        <div className="market-tab">

          <div className="company-row">
            <div className="company-col company-col-left">
              <h3>Mission</h3>
              <p>Our mission is to empower individuals to regenerate money with small investments, create network careers, and become entrepreneurs.</p>
              <h3 style={{ marginTop: 30 }}>Vision</h3>
              <p>Our vision is to make global transactions cashless & fast by leveraging blockchain technology.</p>
            </div>
            <div className="company-col company-col-right">
              <img src="images/cointBeg-removebg-preview.png"
                // style={{
                //   marginLeft: '4rem',
                //   marginTop: 15,
                // }}
                className="visionImage"
                alt="" />
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default OurMission;
