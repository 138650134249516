import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, Link, Outlet, RouterProvider, useNavigate } from "react-router-dom";
import DashboardPage from "./Admin/DashboardPage";
import Admin from './Admin/Index';
import AdminLogin from "./Admin/Screens/AdminLogin";
import CoinAddress from "./Admin/Screens/CoinAddress";
import Plan from "./Admin/Screens/Plan";
import Transaction from "./Admin/Screens/Transaction";
import UserBuyPlan from "./Admin/Screens/UserBuyPlan";
import Users from "./Admin/Screens/Users";
import "./App.css";
import Login from "./auth/Login";
import Register from "./auth/Register";
import About from "./Components/About";
import Policy from "./Components/AboutUs";
import AppSection from "./Components/AppSection";
import ContactForm from "./Components/ContactForm";
import Footer from "./Components/Footer";
import GlobleTransaction from "./Components/GlobleTransaction";
import Header from "./Components/Header";
import Instruction from "./Components/Instruction";
import Market from "./Components/Market";
import OurMission from "./Components/OurMission";
import ProductPage from "./Components/ProductPage";
import RankReward from "./Components/RankReward";
import Terms from "./Components/Terms";
import Trand from "./Components/Trand";
import UserHistory from "./Components/UserHistory";
import chatTelegram from './fb.png';
import chat from "./wa.png";
import DashboardLayout from "./Components/dahboard/DashboardLayout";
import Dashboard from "./Components/dahboard/Dashboard";
import Package from "./Components/dahboard/Package";
import Payment from "./Components/dahboard/Payment";
import Referral from "./Components/dahboard/Referral";
import Profile from "./Components/dahboard/Profile";
import Deposit from "./Admin/Screens/Deposit";
import WithDraw from "./Admin/Screens/Withdraw";
import ReferralLink from "./Components/ReferralLink";
function App() {
  const scrollReveal = function () {
    const sections = document.querySelectorAll("[data-section]");
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].getBoundingClientRect().top < window.innerHeight / 1.5) {
        sections[i].classList.add("active");
      } else {
        sections[i].classList.remove("active");
      }
    }
  };
  const addEventOnElem = function (elem, type, callback) {
    if (elem.length > 1) {
      for (let i = 0; i < elem.length; i++) {
        elem[i].addEventListener(type, callback);
      }
    } else {
      elem.addEventListener(type, callback);
    }
  };
  useEffect(() => {
    scrollReveal();
    addEventOnElem(window, "scroll", scrollReveal);
  }, []);





  const AuthLayout = () => {
    const [isLoader, setIsLoader] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
      const isToken = window.localStorage.getItem("@userTokenId");
      if (isToken) {
        navigate("/user/dashboard", { replace: true });
        setIsLoader(true);
      } else {
        setIsLoader(false);
      }
    }, [])
    return isLoader ?
      <div>
        <p>Please Wait....</p>
      </div>
      : <Outlet />
  }



  const HomeLayout = () => {
    const [isLoader, setIsLoader] = useState(true);
    const [isLogged, setIsLogged] = useState(false);
    useEffect(() => {
      const isToken = window.localStorage.getItem("@userTokenId");
      if (isToken) {
        setIsLogged(true);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    }, [])
    return isLoader ?
      <div>
        <p>Please Wait....</p>
      </div>
      : <>
        <Header isLogged={isLogged} />
        <Outlet />
        <Footer />
      </>
  }

  const routes = createBrowserRouter([
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: <Login />
        },
        {
          path: "register",
          element: <Register />
        }
      ]
    },
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          path: "/",
          Component: () => {

            return (
              <main>
                <article>
                  <section className="section hero active" aria-label="hero" data-section>
                    <img src="images/mainBg.jpg" style={{
                      width: "100%",
                      position: 'absolute',
                      left: 0,
                      top: 0,
                    }} className="maiNImage" />
                    <div className="container">
                      <div className="hero-content">
                        <h1 className="h1 hero-title">
                          Invest Now for Exceptional Profits!
                        </h1>

                        <p className="hero-text">
                          Elevate Your Investment Experience with Fx Nex: Easy, Secure, and Swift Growth for Your Budget.
                        </p>
                        <Link to="/plans"  >
                          <a href="#" className="btn btn-primary">
                            Invest Now
                          </a>
                        </Link>
                      </div>

                      <figure className="hero-banner">
                        <img
                          src="./assets/images/hero-banner.png"
                          width="570"
                          height="448"
                          // style={{ width: '29rem' }}
                          alt="hero banner"
                          className="w-100 animateImage"
                        />
                      </figure>
                    </div>
                  </section>
                  <Trand />
                  <Market />
                  <RankReward />
                  <OurMission />
                  <Instruction />
                  <GlobleTransaction />
                  {/* <Instruction /> */}
                  <AppSection />
                  <About />
                  {/* <WhatsApp /> */}
                  {/* <TelegramButton /> */}
                </article>
              </main>
            );
          }
        },
        {
          path: "/plans",
          element: <ProductPage />,
        },
        {
          path: "/terms",
          element: <Terms />
        },
        {
          path: "/policy",
          element: <Policy />
        },
        {
          path: "/contact",
          element: <ContactForm />
        },
        {
          path: "/history",
          element: <UserHistory />
        },
        {
          path: "/referral",
          element: <ReferralLink />
        },
      ],
    },
    {
      path: "/user",
      element: <DashboardLayout></DashboardLayout>,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />
        },
        {
          path: "package",
          element: <Package />
        },
        {
          path: "payment",
          element: <Payment />
        },
        {
          path: "withdraw",
          element: <Payment />
        },
        {
          path: "referral",
          element: <Referral />
        },
        {
          path: "profile",
          element: <Profile />
        }
      ]
    },
    {
      path: "/admin",
      element: <Admin />,
      children: [
        {
          index: true,
          element: <DashboardPage />
        },
        {
          path: "/admin/users",
          element: <Users />
        },
        {
          path: "/admin/plan",
          element: <Plan />
        },
        {
          path: "/admin/coin-address",
          element: <CoinAddress />
        },
        {
          path: "/admin/transaction",
          element: <Transaction />
        },
        {
          path: "/admin/user-buy-plan",
          element: <UserBuyPlan />
        },
        {
          path: "/admin/login",
          element: <AdminLogin />
        },
        {
          path: "/admin/deposit",
          element: <Deposit />
        },
        {
          path: "/admin/withdraw",
          element: <WithDraw />
        }
      ]
    }
  ])


  return (
    <>
      <RouterProvider router={routes} />
      <Toaster />
    </>
  );
}


const TelegramButton = () => {
  const telegramLink = 'https://t.me/+97471002856';

  const openTelegramChat = () => {
    window.open(telegramLink, '_blank');
  };

  return (

    <img style={{
      position: 'fixed',
      bottom: '70px',
      right: '20px',
      height: "50px",
      width: "50px",
      backgroundColor: '#007bff',
      color: '#fff',
      borderRadius: '100%',

      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      zIndex: '1000', // Adjust the zIndex to make sure it's above other elements
    }}
      onClick={openTelegramChat} src={chatTelegram} alt="telegram" />


  );
};

const WhatsApp = () => {

  const telegramLink = 'https://wa.me/+97471002856';

  const openTelegramChat = () => {
    window.open(telegramLink, '_blank');
  };

  return (
    <img style={{
      position: 'fixed',
      bottom: '10px',
      right: '20px',
      height: "50px",
      width: "50px",
      backgroundColor: '#007bff',
      color: '#fff',
      borderRadius: '100%',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      zIndex: '1000', // Adjust the zIndex to make sure it's above other elements
    }}
      onClick={openTelegramChat} src={chat} alt="what'sppp" />
  );
};

export default App;




