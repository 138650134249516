import React from "react";

function ContactForm() {
  return (
    <div
      style={{
        width: "100%"

      }}
    >
      <section className="section  ">
        <div className="container contact-container">
          <h2>Contact Us</h2>
          <div className="contact-row">
            <div className="contact-col col-left">
              <div className="form-group">
                <label htmlFor="username">
                  <input type="text" id="username" placeholder="Username *" />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <input type="email" id="email" placeholder="Email *" />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="subject">
                  <input type="text" id="subject" placeholder="Subject *" />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  <textarea id="message" style={{
                    width: '100%',
                    height: 100,
                    padding: 10,
                    outline: "none",
                    color: "#fff",
                    border: ' 1px solid #3d4f6b',
                    borderRadius: '5px',
                    backgroundColor: '#1c2a45',
                  }} placeholder="Message *" />
                </label>
              </div>
              <button type="submit" className="login-button">
                Submit
              </button>
            </div>
            <div className="contact-col col-left">
              <img src="images/cointBeg-removebg-preview.png" style={{marginLeft:'3rem'}} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <div className="contactModal"
        style={{
          padding:"20px",
          height: "60%",
          width: "50%",
          background: "white",
          borderRadius: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <h3
          style={{ marginBottom: "20px", marginTop: "30px", fontSize: "30px" }}
        >
          Contact Now
        </h3>
        <div style={{height:"80px"}}    className="field">
          <input
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              height: "100%",
              width: "80%",
              borderRadius: "30px",
              backgroundColor: "transparent",
              border: "0px solid",
              outline: "none",
            }}
            placeholder="Your Name"
          />
        </div>
        <div style={{height:"80px"}}  className="field">
          <input
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              height: "100%",
              width: "80%",
              borderRadius: "30px",
              backgroundColor: "transparent",
              border: "0px solid",
              outline: "none",
            }}
            placeholder="Email Address"
          />
        </div>
        <div style={{height:"80px"}}   className="field">
          <input
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              height: "100%",
              width: "80%",
              borderRadius: "30px",
              backgroundColor: "transparent",
              border: "0px solid",
              outline: "none",
            }}
            placeholder="Subject"
          />
        </div>
        <div className="field Longfield">
          <input
            style={{
              height: "200px",
              paddingLeft: "15px",
              paddingRight: "15px",
              height: "100%",
              width: "80%",
              borderRadius: "30px",
              backgroundColor: "transparent",
              border: "0px solid",
              outline: "none",
            }}
            placeholder="Messege"
          />
        </div>

        <div
          style={{
            height: "50px",
            width: "30%",
            fontWeight: "600",
            background: "#3a3a5e",
            marginTop: "30px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            cursor: "pointer",
            padding:"10px"
          }}
        >
          Submit
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default ContactForm;
