import React, { useRef, useState } from 'react';
import Logo from "../logo.png";
import './style.css';
import { TypeAnimation } from 'react-type-animation';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

const Login = () => {
    const refUse = useRef();
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    // Handle input change
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
        setErrors({
            ...errors,
            [e.target.id]: '', // Clear error for the specific field
        });
    };

    // Validate inputs
    const validate = () => {
        const tempErrors = {};
        if (!state.email) tempErrors.email = 'Email is required.';
        else if (!/\S+@\S+\.\S+/.test(state.email)) tempErrors.email = 'Email is invalid.';
        if (!state.password) tempErrors.password = 'Password is required.';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) return;

        setIsSubmitting(true);
        try {
            const response = await axios.post('https://93.127.163.198:3001/api/v1/user/login', state);
            toast.success('Login Successful!');
            if (response.status === 200) {
                window.localStorage.setItem("@userTokenId", response.data.data?._id);
                navigate("/user/dashboard", { replace: true });
            }
            // Navigate or store the token as needed
        } catch (error) {
            toast.error(error.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="containerLogin">
            <div className="login-wrapper">
                <div className="welcome-section">
                    <div className="logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <h2>Hi, welcome back.</h2>
                    
                    <div className="illustration">
                        <img
                            src="/images/loginImage.png"
                            alt="Illustration"
                        />
                    </div>
                </div>
                <div className="form-section">
                    <div className="getStarted">
                        <p style={{ marginInline: 10 }}>
                            Don't have an account?
                            <Link to={'/auth/register'} className="registerText">
                                Get started
                            </Link>
                        </p>
                    </div>
                    <div className="fom-box">
                        <h2>Sign in to Fx Nex</h2>
                        <p style={{ marginBottom: 20 }}>Enter your details below.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email *"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">
                                    <input
                                        type="password"
                                        id="password"
                                        placeholder="Password *"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                    {errors.password && <span className="error">{errors.password}</span>}
                                </label>
                            </div>
                            <div className="form-actions">
                                <div>
                                    <input type="checkbox" id="remember-me" />
                                    <label htmlFor="remember-me"> Remember me</label>
                                </div>
                                <a href="#" className="forgot-password">
                                    Forgot password?
                                </a>
                            </div>
                            <button type="submit" className="login-button" disabled={isSubmitting}>
                                {isSubmitting ? 'Logging in...' : 'Login'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
