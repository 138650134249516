import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useAppContext } from '../../../Context/AppContext';

const AddCoinAddress = ({ isShow, onClose }) => {
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [loader, setLoader] = useState(false);
    const { addCoin } = useAppContext();

    const onAddCoin = async () => {
        if (title == "" || address == "") {
            toast.error("Please Fill The Form");
        } else {
            setLoader(true);
            await addCoin({ title, coinAddress: address });
            setLoader(false);
            onClose();
        }
    }
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >

                <div className='modal-header'>
                    <h3>{"Add Coin"}</h3>
                </div>
                <div className='modal-body'>
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                }}
                                placeholder="Enter Title"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Address</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                }}
                                placeholder="Enter Address"
                                onChange={(e) => setAddress(e.target.value)}
                                value={address}
                            />
                        </div>
                    </div>

                </div>

                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={() => {
                        onAddCoin()
                    }}>{loader ? "Please Wait..." : "Submit"}</button>
                </div>
            </div>
        </div>
    )
}

export default AddCoinAddress