import React, { useState } from 'react'
import { useAppContext } from '../Context/AppContext'

const VerifyEmail = ({ isShow, onClose }) => {
    const { emailVerify,
        isEmailVerifyLoader } = useAppContext();
    const [code, setCode] = useState("");
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >
                <div className='modal-header'>
                    <h3>{"Verify Email"}</h3>
                </div>
                <div className='modal-body'>
                    <p style={{ textAlign: 'center' }}>To complete your registration, please enter the One-Time Passcode (OTP) sent to your registered email address.</p>
                    <div className="form-group" style={{ marginTop: 20 }}>
                        <label htmlFor="">Otp Code</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                }}
                                placeholder="Enter Otp Code"
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={() => {
                        emailVerify(code)
                    }}>{isEmailVerifyLoader ? "Please Wait..." : "Submit"}</button>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail