import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useAppContext } from '../Context/AppContext';
import CountrySelect from '../ContryData/Conutry';
const Login = ({ isShow, onClose }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [username, setUsername] = useState("");
  const [cnic, setCnic] = useState({
    frontImage: null,
    backImage: null
  });
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [country, setCountry] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { createAccount,
    loginAccount } = useAppContext();
  const onRegister = async () => {
    if (username == "" || email == "" || password == "" || country == "" || cPassword == "") {
      toast.error("Please Enter Email and Password");
    } else if (password != cPassword) {
      toast.error("Password Dose not match");
    } else {
      setIsLoader(true);
      const formData = new FormData();
      formData.append("frontImage", frontImage);
      formData.append("backImage", backImage);
      formData.append("password", password);
      formData.append("email", email);
      formData.append("username", username);
      formData.append("country", country);
      await createAccount(formData,email);
      onClose();
      setIsLoader(false);
    }
  }

  const onLogin = async () => {
    console.log(email, password);
    if (email == "" || password == "") {
      toast.error("Please Enter Email and Password");
    } else {
      setIsLoader(true);
      await loginAccount({ email, password });
      onClose();
      setIsLoader(false);
    }
  }


  const onPickImage = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setFrontImage(e.target.files[0])
    reader.onload = function () {
      setCnic({ ...cnic, frontImage: reader.result });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

  }

  const onPickBackImage = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setBackImage(e.target.files[0])
    reader.onload = function () {
      setCnic({ ...cnic, backImage: reader.result });

    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

  }
  return (
    <div className={`model-box ${isShow ? "show" : "hide"}`}>
      <div className='loginModel'
        style={{
          height: isRegister ? "90vh" : "auto"
        }}
      >
        {isForgotPassword ?
          <div className='modal-header'>
            <h3>{"Forgot Password"}</h3>
            <p style={{ width: "100%" }}>{"Don't"} have an Account <small
              onClick={() => {
                setIsForgotPassword(false);
                setIsRegister(false);
              }}
              style={{
                fontSize: 17, fontWeight: 600, color: "#3772ff",
                cursor: "pointer"
              }}>{"Login"}?</small></p>
          </div>
          : <div className='modal-header'>
            <h3>{isRegister ? "Register" : "Login"}</h3>
            <p style={{ width: "100%" }}>{isRegister ? "If you" : "Don't"} have an Account <small
              onClick={() => {
                setIsForgotPassword(false);
                if (isRegister) {
                  setIsRegister(false);
                } else {
                  setIsRegister(true);
                }
              }}
              style={{
                fontSize: 17, fontWeight: 600, color: "#3772ff",
                cursor: "pointer"
              }}>{isRegister ? "Login" : "Register"}?</small></p>
          </div>}
        {isForgotPassword ?
          <div className='modal-body'>
            <p style={{ textAlign: 'center' }}>If You Forgot Your Password So Please Enter Your valid Email To Change Your Password</p>
            <div className="form-group" style={{ marginTop: 20 }}>
              <label htmlFor="">Email</label>
              <div className="field">
                <input
                  style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    border: "0px solid",
                    outline: "none",
                  }}
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
          </div>
          : <>
            {isRegister ?
              <div className='modal-body'>
                <div className="form-group">
                  <label htmlFor="">Username</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Username"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Password</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Confirm Password</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Confirm Password"
                      onChange={(e) => setCPassword(e.target.value)}
                      value={cPassword}
                    />
                  </div>
                </div>
                <div  style={{marginBottom:"20px"}} className="form-group">
                  <label htmlFor="">Country</label>
                  <CountrySelect
                    onChange={(e) => {
                      setCountry(e.label);
                    }}
                  />
                </div>
                {/* <div className="form-group" style={{ marginTop: 10 }}>
                  <label htmlFor="">Upload Document</label>
                  <div className="field">
                    <Select
                      className="cntryDrop"
                      options={[
                        { label: "Passport", value: "passport" },
                        { label: "Government Id Card", value: "governmentIdCard" },
                      ]}
                      placeholder="Select Document Type"
                    />
                  </div>
                </div> */}
                  <label htmlFor="" >Upload National ID</label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginTop: 10
                  }}
                >
                  <div   id="" style={{ boxSizing:"border-box", overflow:"hidden", borderRadius:"20px",  width:"45%",height:"150px" }}  class=" ">
                    <input onChange={onPickImage}
                      accept='image/*'  
                      type="file" hidden name='frontImage' id='frontImage' />
                    <label htmlFor='frontImage'>
                      <div id="imageContainer">
                        {cnic.frontImage != null ?
                          <div style={{borderRadius:"10px"}} id="dropbox">
                            <img src={cnic.frontImage} />
                          </div>
                          : <div style={{borderRadius:"10px"}} id="dropbox">
                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                            <p>Front</p>
                          </div>}
                      </div>
                    </label>

                  </div>
                  <div id="" style={{ boxSizing:"border-box", overflow:"hidden", borderRadius:"20px", width:"45%",height:"150px" }}  class=" ">
                    <input onChange={onPickBackImage}
                      accept='image/*'
                      type="file" hidden name='backImage' id='backImage' />
                    <label htmlFor='backImage'>
                      <div   id="imageContainer">
                        {cnic.backImage != null ?
                          <div style={{borderRadius:"10px"}} id="dropbox">
                            <img src={cnic.backImage} />
                          </div>
                          : <div style={{borderRadius:"10px"}}  id="dropbox">
                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                            <p>Back</p>
                          </div>}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              : <div className='modal-body'>
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Password</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                </div>
                <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <p style={{ cursor: "pointer", fontWeight: 600 }}
                    onClick={() => {
                      setIsForgotPassword(true);
                      setIsRegister(false);
                    }}
                  >Forgot Password?</p>
                </div>
              </div>}</>}
        <div className='modal-footer'>
          <button onClick={onClose}>Cancel</button>
          <button style={{width:"120px"}}  onClick={() => {
            if (isForgotPassword) {

            } else {
              if (isRegister) {
                onRegister();
              } else {
                onLogin();
              }
            }
          }}>{isLoader ? "Please Wait..." : (isRegister ? "Register" : "Login")}</button>
        </div>
      </div>
    </div>
  )
}

export default Login