import React, { useEffect, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

const Transaction = () => {
  const { allTransaction, BaseApi } = useAppContext();
  const [id, setId] = useState("");
  const [search, setSearch] = useState(allTransaction);
  const [depositBudget, setDepositeBudget] = useState("0");
  const [profitBudget, setProfitBudget] = useState("0");

  useEffect(() => {
    setSearch(allTransaction);
  }, [allTransaction]);

  const onSearch = (text) => {
    if (text.length > 0) {
      const sear = allTransaction.filter((e) =>
        e.user.username.toLowerCase().includes(text.toLowerCase())
      );
      console.log(sear);
      if (sear.length > 0) {
        setSearch(sear);
      } else {
        setSearch([]);
      }
    } else {
      setSearch(allTransaction);
    }
  };

  const [loader, setLoader] = useState(false);
  const onUpdate = async (id) => {
    try {
      setLoader(true);
      const resp = await BaseApi.post("/userByPlan/updateAmount/" + id, { depositBudget, profitBudget });
      if (resp.status == 200) {
        console.log(resp.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }

    }
    setLoader(false);

  }
  return (
    <div>
      <div className="search-box">
        <input
          placeholder="Search here"
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
        <button>Add New</button>
      </div>
      <div className="table-box">
        <table className=" market-table">
          <thead className="table-head">
            <tr className="table-row table-title">
              <th className="table-heading" scope="col">
                #
              </th>
              <th className="table-heading" scope="col">
                User
              </th>
              <th className="table-heading" scope="col">
                Type
              </th>
              <th className="table-heading" scope="col">
                Date
              </th>
              <th className="table-heading" scope="col">
                Budget
              </th>
              <th className="table-heading" scope="col">
                Profit
              </th>
              <th className="table-heading" scope="col">
                Action
              </th>
              {/* <th className='table-heading' scope='col'>Action</th> */}
            </tr>
          </thead>
          <tbody className="table-body">
            {search == null ? (
              <p>Please Wait...</p>
            ) : search.length > 0 ? (
              search?.map((v, i) => {
                return (
                  <tr className="table-row" key={i}>
                    <td>{i + 1}</td>
                    <td>{v?.user.username}</td>
                    <td>{v?.type}</td>
                    <td>{v?.date.split("T")[0]}</td>
                    <td>
                      {" "}
                      $
                      <input
                        style={{
                          marginLeft: "10px",
                          height: "30px",
                          width: "60px",
                        }}
                        onFocus={() => { setId(v?._id) }}
                        onChange={(e) => id == v?._id && setDepositeBudget(e.target.value)}
                        value={id == v?._id ? depositBudget:v?.despositeBudget}
                        type="text"
                      />
                    </td>
                    <td>
                      {" "}
                      $
                      <input
                        onFocus={() => { setId(v?._id) }}

                        onChange={(e) => id == v?._id && setProfitBudget(e.target.value)}
                        value={id == v?._id ? profitBudget:v?.profitBudget}
                        style={{
                          marginLeft: "10px",
                          height: "30px",
                          width: "60px",
                        }}
                        type="text"
                      />
                    </td>

                    <td>
                      <button
                        className="btn "
                        style={{
                          background: "hsl(222, 100%, 61%)",
                          paddingInline: 10,
                          paddingBlock: 5,
                        }}
                        onClick={() => onUpdate(v?._id)}
                      >
                        {loader ? id == v?._id && "please wait..." : "Update"}
                      </button>
                    </td>
                    {/* <td>
                          <i
                            onClick={() => {
                              // setUser(v);
                              // setIsView(true)
                            }}
                            className='bx bx-edit' style={{ cursor: "pointer" }} />
                          <i className='bx bx-trash' style={{ cursor: "pointer" }} />

                        </td> */}
                  </tr>
                );
              })
            ) : (
              <p>No Data Founded</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transaction;
