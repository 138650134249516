import React from "react";
import Logo from '../logo.png'
import { Link, useNavigate } from "react-router-dom";
import { MdFacebook } from "react-icons/md";
import { BsInstagram, BsTiktok } from "react-icons/bs";
const Footer = () => {
  const navigate = useNavigate();
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   navigate(`/`);
  // };
  return (
    <footer class="footer">
      <div class="footer-top" data-section>
        <div style={{ padding: "30px", alignItems: 'start' }} class="container">
          <div class="footer-brand" style={{ marginTop: 20 }}>
            <a onClick={() => {
              navigate("/")
            }} class="logo">
              <img
                src={Logo}
                style={{ width: '150px' }}
                alt="Fx Nex logo"
              />
            </a>
            {/* <h2 class="footer-title">Let's talk! 🤙</h2> */}

            {/* <a href="tel:+123456789101" class="footer-contact-link">
              +97471002856
            </a> */}

            <a href="mailto:info@fxnex.org" style={{ marginTop: 10 }} class="footer-contact-link">
              info@fxnex.org
            </a>

            <address class="footer-contact-link">
              121-59 High Road East Finchley London, N2 8AW United Kingdom.
            </address>
          </div>


          <ul class="footer-list">
            <li>
              <p class="footer-list-title">Support</p>
            </li>
            <li>
              <Link to="/" class="footer-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/plans" class="footer-link">
                Packages
              </Link>
            </li>

            <Link to="/contact" >
              <li>
                <a href="#" class="footer-link">
                  Contact Us
                </a>
              </li>
            </Link>
          </ul>
          <ul class="footer-list">

            <li>
              <p class="footer-list-title">Social Links</p>
            </li>


            <div style={{ display: 'flex', alignItems: 'center', }}>
              <Link to="https://www.facebook.com/smctrader85" target="_blank" >
                <li>
                  {/* <a href="#" class="footer-link"> */}
                  <MdFacebook style={{ fontSize: 30 }} />
                  {/* </a> */}
                </li>
              </Link>
              <Link to="https://www.instagram.com/smctrader885/" target="_blank" >
                <li>
                  {/* <a href="#" class="footer-link"> */}
                  <BsInstagram style={{ fontSize: 25, marginInline: 10 }} />
                  {/* </a> */}
                </li>
              </Link>
              <Link to="https://www.tiktok.com/@smc_trader885?_t=8rwn2AK8Mvx&_r=1" target="_blank" >
                <li>
                  {/* <a href="#" class="footer-link"> */}
                  <BsTiktok style={{ fontSize: 25, marginInline: 10 }} />
                  {/* </a> */}
                </li>
              </Link>
            </div>

            {/* <Link style={{ marginTop: "10px" }} to="/terms" >
              <li>
                <a href="#" class="footer-link">

                </a>
              </li>
            </Link> */}
          </ul>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <p class="copyright">
            &copy; 2024 Fx Nex All Rights Reserved{" "}
            {/* <a href="#" class="copyright-link">
            </a> */}
          </p>

          <ul class="social-list">
            <li>
              <a href="https://www.facebook.com/smctrader85" target="_blank" class="social-link">
                <MdFacebook />

                {/* <ion-icon name="logo-facebook"></ion-icon> */}
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/smctrader885/" target="_blank" class="social-link">
                <BsInstagram style={{}} />
                {/* <ion-icon name="logo-twitter"></ion-icon> */}
              </a>
            </li>


            <li>
              <a href="https://www.tiktok.com/@smc_trader885?_t=8rwn2AK8Mvx&_r=1" target="_blank" class="social-link">
                <BsTiktok style={{}} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;