import React from 'react'

const AddPlan = ({ isShow, onClose }) => {
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >

                <div className='modal-header'>
                    <h3>{"Add Plan"}</h3>
                </div>
                <div className='modal-body'>
                <div className="form-group">
                  <label htmlFor="">Invest From</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Password" 
                    //   onChange={(e) => setPassword(e.target.value)}
                    //   value={password}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Invest To</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Password" 
                    //   onChange={(e) => setPassword(e.target.value)}
                    //   value={password}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Profit From</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Password" 
                    //   onChange={(e) => setPassword(e.target.value)}
                    //   value={password}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Profit To</label>
                  <div className="field">
                    <input
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "0px solid",
                        outline: "none",
                      }}
                      placeholder="Enter Password" 
                    //   onChange={(e) => setPassword(e.target.value)}
                    //   value={password}
                    />
                  </div>
                </div>
                </div>

                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={() => {
                       
                    }}>{"Submit"}</button>
                </div>
            </div>
        </div>
    )
}

export default AddPlan