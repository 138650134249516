// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCR_GwKRH_LFmZuH4Xmn2mTtGodga3nvYk",
    authDomain: "fx-site.firebaseapp.com",
    projectId: "fx-site",
    storageBucket: "fx-site.firebasestorage.app",
    messagingSenderId: "591599542445",
    appId: "1:591599542445:web:16fef087f9f24eeacd2624"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.settings.appVerificationDisabledForTesting = true;
// export default app;
export { app, auth };
