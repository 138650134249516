import React, { useState } from "react";
import { BASEIMAGEURL, useAppContext } from "../../../Context/AppContext";

const ViewUserPlan = ({ isShow, onClose, plan }) => {
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const { approvedOrRejectPlan } = useAppContext();
  const isApprovedOrReject = async (status) => {
    if (status == "APPROVED") {
      setLoader(true);
      await approvedOrRejectPlan(plan._id, status);
      setLoader(false);
      onClose();

    } else {
      setLoader1(true);
      await approvedOrRejectPlan(plan._id, status);
      setLoader1(false);
      onClose();
    }
  };
  return (
    <div className={`model-box ${isShow ? "show" : "hide"}`}>
      <div
        className="loginModel"
        style={{
          height: "auto",
        }}
      >
        <div className="modal-header">
          <h4
            style={{
              fontSize: 20,
              color: "#111",
              fontWeight: 600,
              marginTop: 10,
            }}
          >
            {"View User Plan"}
          </h4>
        </div>
        <div className="modal-body">
          <img
            style={{
              width: "100%",
             
              height: 150,
              border: "1px solid #111",
              borderRadius: 5,
              boxShadow: "2px 2px 5px 1px #ccc",
            }}
            src={BASEIMAGEURL + plan?.image}
          />
          <p
            style={{
              fontSize: 20,
              color: "#111",
              fontWeight: 600,
              marginTop: 10,
            }}
          >
            Coin : {plan?.coins?.title}
          </p>
          <p
            style={{
              fontSize: 16,
              color: "#111",
              fontWeight: 300,
              marginTop: 10,
            }}
          >
            {plan?.coins?.coinAddress}
          </p>
          <p
            style={{
              fontSize: 20,
              color: "#111",
              fontWeight: 600,
              marginTop: 10,
            }}
          >
            WithDraw Address : {plan?.coins?.title}
          </p>
          <p
            style={{
              fontSize: 16,
              color: "#111",
              fontWeight: 300,
              marginTop: 10,
            }}
          >
            {plan?.withdrawalAddress}
          </p>
        </div>
        <div className="modal-footer">
          <button onClick={onClose}>Cancel</button>
          <button
            style={{ width:"120px",  backgroundColor: "red" }}
            onClick={() => {
              isApprovedOrReject("REJECT");
            }}
          >
            {loader1 ? "Please Wait.." : "Reject"}
          </button>
          <button style={{ width:"120px",}}
            onClick={() => {
              isApprovedOrReject("APPROVED");
            }}
          >
            {loader ? "Please Wait.." : "Approved"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewUserPlan;
