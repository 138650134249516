import React from 'react'

const Header = () => {
  return (
    <div className='admin-header'>
      <p style={{fontSize:20,color:"#fff"}}>Admin Panel</p>
    </div>
  )
}

export default Header