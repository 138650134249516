import React from "react";

const RankReward = () => {
  return (
    <section
      className="section instruction"
      aria-label="instruction"
      data-section
    >
      <div className="container">
        <h2 className="h2 section-title">Rank & Reward</h2>

        <p className="section-text">
          Turn your achievements into profits – every rank unlocks greater opportunities and rewards!
        </p>

        <ul className="instruction-list">
          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="./images/laptop.png"
                  width="188"
                  height="110"
                  loading="lazy"
                  alt="Step 1"
                  className="img"
                />
              </figure>

              {/* <p className="card-subtitle">Step 1</p> */}

              <h3 className="h3 card-title">200$ Laptop</h3>
              <button>5K$</button>
              {/* 
              <p className="card-text">
                Register on Our Site for a Seamless Verification Process and Unlock Exclusive Benefits!

              </p> */}
            </div>
          </li>

          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="images/watch.png"
                  width="130"
                  height="96"
                  loading="lazy"
                  alt="Step 2"
                  className="img"
                />
              </figure>

              {/* <p className="card-subtitle">Step 2</p> */}

              <h3 className="h3 card-title">500$ Watch</h3>
              <button>10K$</button>
              {/* <p className="card-text">
                Connect Your Wallet, Invest with Ease, and Enjoy Hassle-free Returns on Your Investment!.
              </p> */}
            </div>
          </li>

          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="images/iphone15.png"
                  width="130"
                  height="96"
                  loading="lazy"
                  alt="Step 3"
                  className="img"
                />
              </figure>

              {/* <p className="card-subtitle">Step 3</p> */}

              <h3 className="h3 card-title">2000$ I Phone</h3>
              <button>50k$</button>
              {/* <p className="card-text">
                Automated Excellence: Your Ratings Generated Instantly for a Seamless Experience!

              </p> */}
            </div>
          </li>

          <li>
            <div className="instruction-card">
              <figure className="card-banner">
                <img
                  src="images/honda.png"
                  width="200"
                  height="96"
                  loading="lazy"
                  alt="Step 4"
                  className="img"
                />
              </figure>
              <h3 className="h3 card-title">7000$ Sport Bike</h3>
              <button>200K$</button>

              {/* <p className="card-text">
                Stay Informed Seamlessly: Your Profits Update Automatically Every 10 Days for Effortless Monitoring!
              </p> */}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default RankReward;
