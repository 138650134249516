import React, { useEffect, useState } from 'react'
import ReferralTable from './Tables/ReferralTable';
import { BaseApi, useAppContext } from '../../Context/AppContext';

const Referral = () => {
  const { userInfo } = useAppContext();
  const [allReferrals1, setAllReferrals1] = useState(null);
  const [allReferrals2, setAllReferrals2] = useState(null);
  const [allReferrals3, setAllReferrals3] = useState(null);
  const [allReferrals4, setAllReferrals4] = useState(null);
  const [allReferrals5, setAllReferrals5] = useState(null);
  const [allReferrals6, setAllReferrals6] = useState(null);



  useEffect(() => {
    getAllReferrals();
    getAllReferrals2();
    getAllReferrals3();
    getAllReferrals4();
    getAllReferrals5();
    getAllReferrals6();
  }, []);

  const getAllReferrals = async () => {
    // http://localhost:3001/api/v1/user/get-referral/A26476B3
    try {
      const response = await BaseApi.get(`/user/get-referral/${userInfo?.referralCode}`);
      if (response.status == 200 && response.data.data?.length > 0) {
        // console.log(response.data.data);
        setAllReferrals1(response.data.data);
      } else {
        setAllReferrals1([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getAllReferrals2 = async () => {
    // http://localhost:3001/api/v1/user/get-referral/A26476B3
    try {
      const response = await BaseApi.get(`/user/get-referral2/${userInfo?.referralCode}`);
      if (response.status == 200 && response.data.data?.length > 0) {
        // console.log(response.data.data);
        setAllReferrals2(response.data.data);
      } else {
        setAllReferrals2([]);
      }
    } catch (error) {
      console.log(error);
    }
  }



  const getAllReferrals3 = async () => {
    // http://localhost:3001/api/v1/user/get-referral/A26476B3
    try {
      const response = await BaseApi.get(`/user/get-referral3/${userInfo?.referralCode}`);
      if (response.status == 200 && response.data.data?.length > 0) {
        // console.log(response.data.data);
        setAllReferrals3(response.data.data);
      } else {
        setAllReferrals3([]);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const getAllReferrals4 = async () => {
    // http://localhost:3001/api/v1/user/get-referral/A26476B3
    try {
      const response = await BaseApi.get(`/user/get-referral4/${userInfo?.referralCode}`);
      if (response.status == 200 && response.data.data?.length > 0) {
        // console.log(response.data.data);
        setAllReferrals4(response.data.data);
      } else {
        setAllReferrals4([]);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const getAllReferrals5 = async () => {
    // http://localhost:3001/api/v1/user/get-referral/A26476B3
    try {
      const response = await BaseApi.get(`/user/get-referral5/${userInfo?.referralCode}`);
      if (response.status == 200 && response.data.data?.length > 0) {
        // console.log(response.data.data);
        setAllReferrals5(response.data.data);
      } else {
        setAllReferrals5([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getAllReferrals6 = async () => {
    // http://localhost:3001/api/v1/user/get-referral/A26476B3
    try {
      const response = await BaseApi.get(`/user/get-referral6/${userInfo?.referralCode}`);
      if (response.status == 200 && response.data.data?.length > 0) {
        // console.log(response.data.data);
        setAllReferrals6(response.data.data);
      } else {
        setAllReferrals6([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div >
      <Tabs
        allReferrals1={allReferrals1}
        allReferrals2={allReferrals2}
        allReferrals3={allReferrals3}
        allReferrals4={allReferrals4}
        allReferrals5={allReferrals5}
        allReferrals6={allReferrals6}

      />
    </div>
  )
}







const Tabs = ({ allReferrals2, allReferrals1, allReferrals3, allReferrals4, allReferrals5, allReferrals6 }) => {


  const [activeTab, setActiveTab] = useState("Tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "Tab1":
        return <ReferralTable level={1} data={allReferrals1} />;
      case "Tab2":
        return <ReferralTable level={2} data={allReferrals2} />;
      case "Tab3":
        return <ReferralTable level={3} data={allReferrals3} />;
      case "Tab4":
        return <ReferralTable level={4} data={allReferrals4} />;
      case "Tab5":
        return <ReferralTable level={5} data={allReferrals5} />;
      case "Tab6":
        return <ReferralTable level={6} data={allReferrals6} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-buttons">
        <button
          className={activeTab === "Tab1" ? "active" : ""}
          onClick={() => setActiveTab("Tab1")}
        >
          Level 1
        </button>
        <button
          className={activeTab === "Tab2" ? "active" : ""}
          onClick={() => setActiveTab("Tab2")}
        >
          Level 2
        </button>
        <button
          className={activeTab === "Tab3" ? "active" : ""}
          onClick={() => setActiveTab("Tab3")}
        >
          Level 3
        </button>
        <button
          className={activeTab === "Tab4" ? "active" : ""}
          onClick={() => setActiveTab("Tab4")}
        >
          Level 4
        </button>
        <button
          className={activeTab === "Tab5" ? "active" : ""}
          onClick={() => setActiveTab("Tab5")}
        >
          Level 5
        </button>
        <button
          className={activeTab === "Tab6" ? "active" : ""}
          onClick={() => setActiveTab("Tab6")}
        >
          Level 6
        </button>
      </div>
      <div className=" referralBox">{renderContent()}</div>
    </div>
  );
};



export default Referral