import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../logo.png';
import './sidebar.scss';

const sidebarNavItems = [
    {
        display: 'Dashboard',
        icon: <i className='bx bx-home'></i>,
        to: '/admin',
        section: ''
    },
    {
        display: 'User',
        icon: <i className='bx bx-user'></i>,
        to: '/admin/users',
        section: 'Users'
    },
    {
        display: 'Deposit',
        icon: <i className='bx bx-credit-card'></i>,
        to: '/admin/deposit',
        section: 'Deposits'
    },
    {
        display: 'WithDraw',
        icon: <i className='bx bx-credit-card'></i>,
        to: '/admin/withdraw',
        section: 'WithDraw'
    },
    {
        display: 'Plan',
        icon: <i className='bx bx-calendar'></i>,
        to: '/admin/plan',
        section: 'plan'
    },
    {
        display: 'Payment Method',
        icon: <i className='bx bx-credit-card'></i>,
        to: '/admin/coin-address',
        section: 'CoinAddress'
    },
    // {
    //     display: 'User Buy Plans',
    //     icon: <i className='bx bx-calendar'></i>,
    //     to: '/admin/user-buy-plan',
    //     section: 'UserBuyPlan'
    // },
    // {
    //     display: 'Transaction',
    //     icon: <i className='bx bx-receipt'></i>,
    //     to: '/admin/transaction',
    //     section: 'Transaction'
    // },
    {
        display: 'Logout',
        icon: <i className='bx bx-log-out'></i>,
        to: '/admin/login',
        section: 'Logout'
    },
]

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
            indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
            setStepHeight(sidebarItem.clientHeight);
        }, 50);
    }, []);

    // change active index
    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return <div className='sidebar'>
        <div className="sidebar__logo">
            <img src={Logo} style={{ width: 130 }} alt="" />
            {/* FX Nex Admin */}
        </div>
        <div ref={sidebarRef} className="sidebar__menu">
            <div
                ref={indicatorRef}
                className="sidebar__menu__indicator"
                style={{
                    transform: `translateX(-50%) translateY(${activeIndex * stepHeight}px)`
                }}
            ></div>
            {
                sidebarNavItems.map((item, index) => (
                    <Link
                        onClick={() => {
                            if (item.display == "Logout") {
                                window.localStorage.removeItem("@AdminToken");
                                window.location.reload();
                            }
                        }}
                        to={item.to} key={index}>
                        <div className={`sidebar__menu__item ${location.pathname === item.to ? 'active' : ''}`}>
                            <div className="sidebar__menu__item__icon">
                                {item.icon}
                            </div>
                            <div className="sidebar__menu__item__text">
                                {item.display}
                            </div>
                        </div>
                    </Link>
                ))
            }
        </div>
    </div>;
};

export default Sidebar;