import React, { useState } from "react";
import Select from "react-select";
import data from "../ContryData/data.json";
import "./styles.css";

const CountrySelect = ({ onChange }) => {
  const [selectedCountry, setSelectedCountry] = useState({});

  return (
    <Select
      className="cntryDrop"
      options={data.countries}
      placeholder="Select Country"
      value={selectedCountry}
      onChange={(selectedOption) => {
        setSelectedCountry(selectedOption);
        onChange(selectedOption)
      }}
    />
  );
};
export default CountrySelect;
