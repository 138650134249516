import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const ReferralLink = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('ref');
        console.log(code)
        if (code) {
            window.localStorage.setItem("@RefCode", code);
            navigate("/auth/register");
        }
    }, [])
    return (
        <div>ReferralLink</div>
    )
}

export default ReferralLink