import React from "react";

const About = () => {
  return (
    <section className="section about" aria-label="about" data-section>
      <div className="container">
        <figure className="about-banner">
          <img
            src="./assets/images/about-banner.png"
            width="748"
            height="436"
            loading="lazy"
            alt="about banner"
            className="w-100"
          />
        </figure>

        <div className="about-content">
          <h2 className="h2 section-title">What Is Fx Nex</h2>

          <p className="section-text">
          Dive into the world of financial growth with Fx Nex, your premier investment platform. Whether you're a seasoned investor or just starting, Fx Nex offers a secure and user-friendly environment to invest your funds and reap the rewards of awesome profits. Our platform is designed to cater to your financial goals, providing a seamless experience that combines innovation, reliability, and unparalleled potential for returns. Join Fx Nex today and let your investments thrive in the pursuit of financial excellence.

          </p>

          {/* <ul className="section-list">
            <li className="section-item">
              <div className="title-wrapper">
                <ion-icon name="checkmark-circle" aria-hidden="true"></ion-icon>

                <h3 className="h3 list-title">
                  View real-time cryptocurrency prices
                </h3>
              </div>

              <p className="item-text">
                Experience a variety of trading on Bitcost. You can use various
                types of coin transactions such as Spot Trade, Futures Trade,
                P2P, Staking, Mining, and margin.
              </p>
            </li>

            <li className="section-item">
              <div className="title-wrapper">
                <ion-icon name="checkmark-circle" aria-hidden="true"></ion-icon>

                <h3 className="h3 list-title">
                  Buy and sell BTC, ETH, XRP, OKB, Etc...
                </h3>
              </div>

              <p className="item-text">
                Experience a variety of trading on Bitcost. You can use various
                types of coin transactions such as Spot Trade, Futures Trade,
                P2P, Staking, Mining, and margin.
              </p>
  </li>*/}
          {/* </ul>  */}

          <a href="#" className="btn btn-primary">
            Explore More
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
