import React from "react";

const AppSection = () => {
  return (
    <section className="section app" style={{ padding: '10px 6rem' }} aria-label="app" data-section>
      <div className="container">
        <div className="app-content">
          <h2 className="h2 section-title" style={{ fontSize: "3.5rem" }}>
            Free Your Money & Invest With Confident
          </h2>

          <p className="section-text">
            With Fx Nex Trade, you can be sure your trading skills are matched

          </p>

          <ul className="section-list">
          

            <li className="section-item">
              <div className="title-wrapper"  style={{gap:0}}>
                <ion-icon name="checkmark-circle" aria-hidden="true"></ion-icon>

                <h3 className="h3 item-title" style={{margin:0}}>Take Control Of Your Wealth</h3>
              </div>

              <p className="item-text" style={{marginLeft:5}}>
                Rest Assured You (And Only You) Have Access To Your Funds
              </p>
            </li>
          </ul>

          <div className="app-wrapper">
            <a href="#">
              <img
                src="./assets/images/googleplay.png"
                width="135"
                height="40"
                loading="lazy"
                alt="get it on google play"
              />
            </a>

            <a href="#">
              <img
                src="./assets/images/appstore.png"
                width="120"
                height="40"
                loading="lazy"
                alt="download on the app store"
              />
            </a>
          </div>
        </div>

        <div className="app-banner">
          <img
            src="./assets/images/app-banner.png"
            width="618"
            height="526"
            loading="lazy"
            alt="app banner"
            className="w-100"
          />

          <span className="span">Scan To Download</span>
        </div>
      </div>
    </section>
  );
};

export default AppSection;
