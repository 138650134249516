import React from 'react';
import { useDashboardContext } from '../Context/DashboardContext';

const DriectTable = () => {
    const { allDirectReward } = useDashboardContext();
    return (
        <div className=''>
            <table className='market-table '>
                <thead className='table-head'>
                    <tr className='table-row table-title'>
                        <th className="table-heading" scope="col">Id</th>
                        <th className="table-heading" scope="col">Username</th>
                        <th className="table-heading" scope="col">Deposit</th>
                        <th className="table-heading" scope="col">Percentage Amount</th>
                        <th className="table-heading" scope="col">Status</th>
                        {/* <th className="table-heading" scope="col">Action</th> */}

                    </tr>
                </thead>
                <tbody style={{ position: allDirectReward?.length > 0 ? "inherit" : "relative" }}>
                    {
                        allDirectReward == null ?
                            <p>Please Wait</p> :
                            allDirectReward?.length > 0
                                ? allDirectReward?.map((d, k) => {
                                    return (
                                        <tr className="table-row" key={k}>
                                            <td>{k + 1}</td>
                                            <td>{d?.refUser?.username}</td>
                                            <td>{d?.userDeposit}$</td>

                                            <td>{d?.amount}$</td>
                                            <td>
                                                {d?.status ? "Approved" : "Pending"}
                                            </td>


                                        </tr>
                                    );
                                }) : <div style={{
                                    position: 'absolute',
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: "center",
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        marginTop: "4rem"
                                    }}>
                                        <p>No Data Found</p>
                                        <img style={{ width: 100 }} src='images/nodataImage.png' />
                                    </div>
                                </div>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default DriectTable