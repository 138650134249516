import React, { useEffect } from 'react';
import { useAppContext } from '../../../Context/AppContext';
import { useDashboardContext } from '../Context/DashboardContext';

const ReferralTable = ({ level = 0, data }) => {
    // const { getAllReferrals, allReferrals } = useDashboardContext();
    // const { userInfo } = useAppContext();

    // useEffect(() => {
    //     const refCode = userInfo?.referralCode;
    //     getAllReferrals(refCode);
    // }, [])



    const newData = data && level == 1 ? data[0]?.referredUsers : data && level == 2 ? data[0]?.subReferredUsers :
        data && level == 3 ? data[0]?.subSubReferredUsers :
            data && level == 4 ? data[0]?.subSub4ReferredUsers :
                data && level == 5 ? data[0]?.subSub5ReferredUsers :
                    data && level == 6 ? data[0]?.subSub6ReferredUsers :
                        []
    return (
        <div className=''>
            <table className='market-table '>
                <thead className='table-head'>
                    <tr className='table-row table-title'>
                        <th className="table-heading" scope="col">Id</th>
                        <th className="table-heading" scope="col">User name</th>
                        <th className="table-heading" scope="col">Plan Count</th>
                        <th className="table-heading" scope="col">Deposit</th>
                        <th className="table-heading" scope="col">{level == 0 ? "Level" : "Level " + level}</th>
                        <th className="table-heading" scope="col">Date</th>
                        {/* <th className="table-heading" scope="col">Action</th> */}
                    </tr>
                </thead>
                <tbody style={{ position: newData?.length > 0 ? "inherit" : "relative" }}>
                    {
                        newData == null ?
                            <p>Please Wait</p> :
                            newData?.length > 0
                                ? newData?.map((d, k) => {
                                    console.log(d?.createdAt);
                                    return (
                                        <tr className="table-row" key={k}>
                                            <td>{k + 1}</td>
                                            <td>{d?.username}</td>
                                            <td>{d?.plans?.length}</td>
                                            <td>
                                                {
                                                    d?.plans?.length > 0
                                                        ? d.plans.map(p => p?.planDetails?.invest?.from).join(",") + "$"
                                                        : "0$"
                                                }
                                            </td>
                                            <td>Level 1</td>
                                            <td>
                                                {d?.createdAt?.split("T")[0]}
                                            </td>
                                        </tr>
                                    );
                                }) : <div style={{
                                    position: 'absolute',
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: "center",
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        marginTop: "4rem"
                                    }}>
                                        <p>No Data Found</p>
                                        <img style={{ width: 100 }} src='images/nodataImage.png' />
                                    </div>
                                </div>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ReferralTable