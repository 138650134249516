import React from "react";
import TotalCards from "./Components/TotalCards";
import { useAppContext } from "../Context/AppContext";

function DashboardPage() {
  
  const {allUsers,userPlan,allTransaction} = useAppContext();
  return (
    <div
      style={{
        marginTop:"20px",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <TotalCards title="Total Users" numbers={allUsers?.length} />
      <TotalCards title="Total Plans" numbers={userPlan?.length} />
      <TotalCards title="Total Transactions" numbers={allTransaction?.length} />

       
    </div>
  );
}

export default DashboardPage;
