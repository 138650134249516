import React, { useEffect, useState } from 'react'
import AddPlan from '../Components/AddPlan/AddPlan';
import { useAppContext } from '../../Context/AppContext';

const Plan = () => {
  const [isShow, setIsShow] = useState(false);
  const { allPlan } = useAppContext();

  const [searchPlan, setSearchPlan] = useState(allPlan);
  useEffect(() => {
    setSearchPlan(allPlan);
  }, [allPlan]);



  // const search = (text) => {
  //   if(text.length>0){
  //     const search = allUsers.filter((e) => (e.username.toLowerCase().includes(text.toLowerCase())||
  //   e.email.toLowerCase().includes(text.toLowerCase())||e.country.toLowerCase().includes(text.toLowerCase())) && e);
  //   console.log(search);
  //   if(search.length>0){
  //     setSearchPlan(search);
  //   }
  //   }
  //   else{
  //      setSearchPlan(allUsers);
  //   }
  // }

  return (
    <div>
      <div className='search-box'>
        <input placeholder='Search here' />
        <button
          onClick={() => {
            setIsShow(true);
          }}
        >Add New</button>
      </div>
      <div className='table-box'>
        <table className=' market-table'>
          <thead className='table-head'>
            <tr className='table-row table-title'>
              <th className='table-heading' scope='col'>#</th>
              <th className='table-heading' scope='col'>Invest</th>
              <th className='table-heading' scope='col'>Plan Type</th>
              <th className='table-heading' scope='col'>Profit From</th>
              <th className='table-heading' scope='col'>Profit To</th>
              <th className='table-heading' scope='col'>Status</th>
              <th className='table-heading' scope='col'>Action</th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {
              searchPlan == null ? <p>Please Wait...</p> :
                searchPlan.length > 0 ?
                  searchPlan?.map((v, i) => {
                    return (
                      <tr className='table-row' key={i}>
                        <td style={{ paddingLeft: 10 }}>{i + 1}</td>
                        <td>{v?.invest?.from}$</td>
                        <td>{v?.type}</td>
                        <td>{v?.profit?.from}%</td>
                        <td>{v?.profit?.to}%</td>
                        <td>{v.status ? "Active" : "InActive"}</td>
                        <td>
                          <i className='bx bx-edit' />
                          <i className='bx bx-trash' />
                        </td>
                      </tr>
                    );
                  })
                  : <p>No Data Founded</p>
            }
          </tbody>
        </table>
      </div>
      <AddPlan isShow={isShow} onClose={() => setIsShow(false)} />
    </div>
  )
}

export default Plan